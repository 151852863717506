import { useCallback, useRef } from "react"
import useMergedRef from "@react-hook/merged-ref"
import { IntersectionOptions, useInView } from "react-intersection-observer"

type UseScrollIntoViewOption = Omit<IntersectionOptions, "skip"> & {
  skipInViewCheck?: boolean
  extraTopOffset?: number
  marginBottom?: number
  marginTop?: number
  behavior?: ScrollBehavior
}

export const useScrollIntoViewIfNeeded = ({
  skipInViewCheck,
  extraTopOffset = 0,
  marginBottom = 0,
  marginTop = 0,
  threshold = 0.5,
  behavior = "auto",
  ...intersectionOptions
}: UseScrollIntoViewOption) => {
  const elementRef = useRef<HTMLDivElement>(null)

  const { ref: isInViewRef, inView } = useInView({
    skip: skipInViewCheck,
    threshold,
    rootMargin: `-${marginTop}px 0px -${marginBottom}px 0px`,
    ...intersectionOptions,
  })

  const mergedRef = useMergedRef(elementRef, isInViewRef)

  const scrollIntoView = useCallback(() => {
    // NOTE: There is a slight bug where inView returns true even if it's not in view, when we are toggling skipInViewCheck
    // i.e. If something is in view, then we set skipInViewCheck to true, then scroll to where it's not in view,
    // then set skipInViewCheck to false, it will still return true
    if (skipInViewCheck || !inView) {
      elementRef.current?.scrollIntoView({ block: "end" })
      // Scroll down more to account for marginBottom and any extra spacing
      window.scrollBy({
        top: marginBottom + extraTopOffset,
        behavior,
      })
    }
  }, [marginBottom, extraTopOffset, inView, skipInViewCheck, behavior])

  return [mergedRef, scrollIntoView, inView] as const
}
