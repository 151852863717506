import React from "react"
import { Text } from "@opensea/ui-kit"
import { Link } from "@/components/common/Link"
import { Modal } from "@/design-system/Modal"
import { useTranslate } from "@/hooks/useTranslate"
import { SUPPORT_URL } from "../../constants"

type DelistedNoticeProps = {
  onClose: () => void
  variant: "account" | "home"
}

export const DelistedNoticeModal = ({
  onClose,
  variant,
}: DelistedNoticeProps) => {
  const t = useTranslate("modals")
  return (
    <>
      <Modal.Header>
        <Modal.Header.Title>
          {variant === "account"
            ? t(
                "delistedNotice.unavailableItem.withAccount.title",
                "This item is no longer available on OpenSea",
              )
            : t(
                "delistedNotice.unavailableItem.withoutAccount.title",
                "The item you tried to visit is no longer available on OpenSea",
              )}
        </Modal.Header.Title>
      </Modal.Header>

      <Modal.Body>
        {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
        <div className="DelistedNotice--content">
          <Text asChild>
            <p>
              {variant === "account"
                ? t(
                    "delistedNotice.unavailableItem.withAccount.description",
                    `This item is no longer available on OpenSea. It will not be visible or
                accessible to anyone browsing the site or your profile.`,
                  )
                : t(
                    "delistedNotice.unavailableItem.withoutAccount.description",
                    `The item you tried to visit is no longer available on OpenSea. It will not be visible or
                accessible to anyone browsing the site.`,
                  )}
            </p>
          </Text>
          <Text asChild>
            <p>
              {t(
                "delistedNotice.learnMoreUnavailableItem.descrption",
                `
            To learn more about why {{item}} is no longer available, read {{helpCenterLink}}. If you have questions or concerns regarding this action, contact
            the OpenSea team {{supportLink}}.`,
                {
                  item:
                    variant === "account"
                      ? t(
                          "delistedNotice.withAccount.item.fragment",
                          "this item",
                        )
                      : t(
                          "delistedNotice.withoutAccount.itemVisited.fragment",
                          "the item you tried to visit",
                        ),
                  helpCenterLink: (
                    <Link href="https://support.opensea.io/articles/8867135">
                      {t(
                        "delistedNotice.helpcenter.link",
                        "our Help Center guide on this topic",
                      )}
                    </Link>
                  ),
                  supportLink: (
                    <Link href={SUPPORT_URL}>
                      {t("delistedNotice.here.link", "here")}
                    </Link>
                  ),
                },
              )}
            </p>
          </Text>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Modal.Footer.Button variant="secondary" onClick={onClose}>
          {t("common.close", "Close")}
        </Modal.Footer.Button>
      </Modal.Footer>
    </>
  )
}
