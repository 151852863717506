import React from "react"
import {
  Icon,
  MaterialIcon,
  Checkbox,
  Text,
  classNames,
  Alert,
  Flex,
} from "@opensea/ui-kit"
import { useForm } from "react-hook-form"
import { graphql, useFragment } from "react-relay"
import styled from "styled-components"
import { Link } from "@/components/common/Link"
import { ACCOUNT_COLLECTION_VERIFICATION_ARTICLE } from "@/constants/support-articles"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { List } from "@/design-system/List"
import { useToasts } from "@/hooks/useToasts"
import {
  CollectionVerificationForm_data$key,
  CollectionVerificationRequestStatus,
} from "@/lib/graphql/__generated__/CollectionVerificationForm_data.graphql"
import { CollectionVerificationFormRequestMutation } from "@/lib/graphql/__generated__/CollectionVerificationFormRequestMutation.graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"
import { selectClassNames } from "@/lib/helpers/styling"
import { TERMS_OF_SERVICE_URL } from "../../../../../constants"

type Props = {
  dataKey: CollectionVerificationForm_data$key
}

type FormData = {
  originalWorkAccepted: boolean
  termsOfServiceAccepted: boolean
}

type Requirement = {
  title: React.ReactNode
  completed: boolean
}

type CheckboxRequirement = Requirement & {
  formName: "originalWorkAccepted" | "termsOfServiceAccepted"
}

type AlertContent = {
  icon: MaterialIcon
  className: string
  iconOutlined?: boolean
  text: React.ReactNode
}

export const CollectionVerificationForm = ({ dataKey }: Props) => {
  const { mutate } = useGraphQL()
  const { attempt, showSuccessMessage } = useToasts()
  const collection = useFragment(
    graphql`
      fragment CollectionVerificationForm_data on CollectionType {
        relayId
        name
        imageUrl
        bannerImageUrl
        connectedTwitterUsername
        owner {
          config
        }
        canViewVerificationForm
        verificationRequest {
          status
          resubmissionAllowed
        }
      }
    `,
    dataKey,
  )

  const { canViewVerificationForm, verificationRequest } = collection
  const existingRequestStatus = verificationRequest?.status
  const hasExistingVerificationRequest = !!existingRequestStatus
  const { watch, setValue } = useForm<FormData>({
    defaultValues: {
      originalWorkAccepted: hasExistingVerificationRequest,
      termsOfServiceAccepted: hasExistingVerificationRequest,
    },
  })

  if (!canViewVerificationForm) {
    throw new Error("Cannot view verification form")
  }

  const watchOriginalWorkAccepted = watch("originalWorkAccepted")
  const watchTermsOfServiceAccepted = watch("termsOfServiceAccepted")

  const submitRequest = async () => {
    await attempt(async () => {
      const variables = {
        collection: collection.relayId,
        originalWorkAccepted: watchOriginalWorkAccepted,
      }
      await mutate<CollectionVerificationFormRequestMutation>(
        graphql`
          mutation CollectionVerificationFormRequestMutation(
            $collection: CollectionRelayID!
            $originalWorkAccepted: Boolean!
          ) {
            collections {
              requestVerification(
                collection: $collection
                originalWorkAccepted: $originalWorkAccepted
              ) {
                status
              }
            }
          }
        `,
        variables,
        {
          shouldAuthenticate: true,
          updater: store => {
            const payload = store.getRootField("collections")
            const verificationRequest = payload.getLinkedRecord(
              "requestVerification",
              variables,
            )
            const collectionRecord = store.get(collection.relayId)
            collectionRecord?.setLinkedRecord(
              verificationRequest,
              "verificationRequest",
            )
          },
        },
      )
      await showSuccessMessage("Badge requested")
    })
  }

  const profileCompleted =
    !!collection.name && !!collection.imageUrl && !!collection.bannerImageUrl

  const ownerAccountVerified = collection.owner?.config === "VERIFIED"
  const connectedTwitter = !!collection.connectedTwitterUsername

  // verificationRequest can be null when one doesn't exist yet, so we
  // explicitly check for false rather than a falsy value.
  const formDisabled = verificationRequest?.resubmissionAllowed === false

  const requirementsMet =
    profileCompleted && ownerAccountVerified && connectedTwitter

  const checkboxRequirementsMet =
    watchOriginalWorkAccepted && watchTermsOfServiceAccepted

  const submitAllowed =
    !formDisabled && requirementsMet && checkboxRequirementsMet

  const requirements: Requirement[] = [
    {
      title: "Set a collection name, profile image and banner",
      completed: profileCompleted,
    },
    {
      title: (
        <>
          Verify the collection owner's{" "}
          <Link href="/account/settings" target="_blank">
            account
          </Link>
        </>
      ),
      completed: ownerAccountVerified,
    },
    {
      title: "Connect the collection's Twitter account",
      completed: connectedTwitter,
    },
  ]

  const checkboxRequirements: CheckboxRequirement[] = [
    {
      title: "I confirm that this is an original work",
      formName: "originalWorkAccepted",
      completed: watchOriginalWorkAccepted,
    },
    {
      title: (
        <>
          I agree to, and confirm my compliance with, OpenSea's{" "}
          <Link href={TERMS_OF_SERVICE_URL} target="_blank">
            Terms of Service
          </Link>
        </>
      ),
      formName: "termsOfServiceAccepted",
      completed: watchTermsOfServiceAccepted,
    },
  ]

  if (existingRequestStatus === "APPROVED") {
    return (
      <Container>
        <ExistingRequestAlert status={existingRequestStatus} />
      </Container>
    )
  }

  return (
    <Container>
      {existingRequestStatus && (
        <ExistingRequestAlert status={existingRequestStatus} />
      )}
      <Block
        className={selectClassNames("CollectionVerificationForm", {
          disabled: formDisabled,
        })}
      >
        <List className="mb-2">
          {requirements.map((requirement, index) => (
            <List.Item key={index}>
              <List.Item.Content>
                <List.Item.Title
                  className={selectClassNames("CollectionVerificationForm", {
                    "incomplete-prerequisite-title": !requirement.completed,
                  })}
                >
                  {requirement.title}
                </List.Item.Title>
              </List.Item.Content>
              <List.Item.Side>
                <Text
                  className={classNames(
                    selectClassNames("CollectionVerificationForm", {
                      "complete-requirement": requirement.completed,
                    }),
                  )}
                  color="secondary"
                  size="small"
                  weight="semibold"
                >
                  <Flex className="items-center">
                    {requirement.completed && (
                      <Icon
                        // eslint-disable-next-line tailwindcss/no-custom-classname
                        className="CollectionVerificationForm--complete-checkmark"
                        size={16}
                        value="check"
                      />
                    )}
                    {requirement.completed ? "Complete" : "Incomplete"}
                  </Flex>
                </Text>
              </List.Item.Side>
            </List.Item>
          ))}
        </List>
        <CheckboxRequirementList>
          {checkboxRequirements.map((requirement, index) => (
            <CheckboxRequirementItem key={index}>
              <Checkbox
                checked={requirement.completed}
                disabled={formDisabled || !requirementsMet}
                onCheckedChange={value => setValue(requirement.formName, value)}
              />
              <Text
                className="ml-2"
                color="secondary"
                size="small"
                weight="semibold"
              >
                {requirement.title}
              </Text>
            </CheckboxRequirementItem>
          ))}
        </CheckboxRequirementList>
      </Block>
      <Button
        className="mt-4"
        disabled={!submitAllowed}
        onClick={submitRequest}
      >
        Request badge
      </Button>
    </Container>
  )
}

const CheckboxRequirementList = styled.ul`
  margin-top: 16px;
`

const CheckboxRequirementItem = styled.li`
  align-items: center;
  color: ${props => props.theme.colors.text.primary};
  display: flex;
  margin-top: 8px;
`

const ExistingRequestAlert = ({
  status,
}: {
  status: CollectionVerificationRequestStatus
}) => {
  const variants = new Map<CollectionVerificationRequestStatus, AlertContent>([
    [
      "APPROVED",
      {
        icon: "verified",
        className: "text-blue-3",
        text: "Your request has been reviewed and your collection has been approved for a badge.",
      },
    ],
    [
      "PENDING",
      {
        icon: "pageview",
        className: "text-yellow-2",
        iconOutlined: true,
        text: "Your request is being reviewed. This may take up to 7 days. We'll send you an email when we've completed the review and you can also check back here for updates.",
      },
    ],
    [
      "DENIED",
      {
        icon: "error",
        className: "text-red-2",
        text: (
          <>
            Your request has been denied. We've reviewed your request and
            unfortunately your collection is not eligible for a badge at this
            time.{" "}
            <Link href={ACCOUNT_COLLECTION_VERIFICATION_ARTICLE}>
              Learn more
            </Link>
          </>
        ),
      },
    ],
  ])

  const content = variants.get(status)
  if (!content) {
    throw new Error(
      `Unsupported collection verification request status: ${status}`,
    )
  }
  return (
    <Alert className="mb-4">
      <Flex className="items-center">
        <Icon
          className={content.className}
          size={20}
          value={content.icon}
          variant={content.iconOutlined ? "outlined" : undefined}
        />
        <Text
          asChild
          className="ml-4"
          color="primary"
          size="small"
          weight="semibold"
        >
          <p>{content.text}</p>
        </Text>
      </Flex>
    </Alert>
  )
}

const Container = styled(Block)`
  .CollectionVerificationForm--disabled {
    opacity: 0.5;
  }

  .CollectionVerificationForm--complete-requirement {
    color: ${props => props.theme.colors.text.primary};
  }

  .CollectionVerificationForm--complete-checkmark {
    color: ${props => props.theme.colors.success};
    margin-right: 2px;
  }
`
