import React, {
  memo,
  ReactNode,
  ReactElement,
  ComponentProps,
  useCallback,
} from "react"
import {
  breakpoints,
  useIsLessThanLg,
  useIsLessThanSm,
  UnstyledButton,
  Text,
  Spinner,
  FlexStart,
  FlexColumn,
  Flex,
  Skeleton,
} from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled, { css, CSSProperties } from "styled-components"
import { ItemCardCta } from "@/components/assets/ItemCardCta/ItemCardCta.react"
import { CollectionTooltip } from "@/components/collections/CollectionTooltip.react"
import { Overflow, OverflowContainer } from "@/components/common/Overflow"
import { VerifiedIcon } from "@/components/common/VerifiedIcon.react"
import { AssetCardVariant } from "@/components/search/assets/AssetSearchView"
import { RarityIndicator } from "@/components/search/rarity/RarityIndicator.react"
import { useIsRarityEnabled } from "@/components/search/rarity/useIsRarityEnabled"
import { Block } from "@/design-system/Block"
import { Checkmark } from "@/design-system/Checkmark"
import { Tooltip } from "@/design-system/Tooltip"
import { useCanceledListingsItemRelayIds } from "@/features/account/components/AccountPage/components/ProfileListingsContextProvider.react"
import { useIsLiveUpdatesEnabledForCollection } from "@/features/live-updates/hooks/useIsLiveUpdatesEnabledForCollection"
import { useOwnerListingPriceOnAccountPage } from "@/hooks/useFlag"
import { useRouter } from "@/hooks/useRouter"
import { useTranslate } from "@/hooks/useTranslate"
import {
  ItemCardFooter$data,
  ItemCardFooter$key,
} from "@/lib/graphql/__generated__/ItemCardFooter.graphql"
import {
  ItemCardFooter_bestAskV2$data,
  ItemCardFooter_bestAskV2$key,
} from "@/lib/graphql/__generated__/ItemCardFooter_bestAskV2.graphql"
import { RarityIndicator_data$key } from "@/lib/graphql/__generated__/RarityIndicator_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { getCollectionUrl } from "@/lib/helpers/collection"
import { dateFromISO8601 } from "@/lib/helpers/datetime"
import { bn } from "@/lib/helpers/numberUtils"
import { getTokenIdSuffix } from "@/lib/helpers/stringUtils"
import { themeVariant } from "@/styles/styleUtils"
import { useItemCardCta } from "./ItemCardCta/useItemCardCta"
import { ItemCardPendingTxnTooltip } from "./ItemCardPendingTxnTooltip.react"
import { ItemCardPrice } from "./ItemCardPrice.react"
import { ItemMetadata } from "./ItemMetadata.react"

export const SHOULD_NOT_ANIMATE_TRANSACTION_STATUS = [
  "Cancel",
  "Dropped",
  "Failed",
]

type Props = {
  accountAddress?: string
  item: ItemCardFooter$key | null
  disabled?: boolean
  showCollectionName?: boolean
  variant?: AssetCardVariant
  children?: (_: {
    renderPrice: (
      priceProps?: Partial<ComponentProps<typeof ItemCardPrice>>,
      noBottomMargin?: boolean,
      noRightPadding?: boolean,
    ) => ReactNode
    title?: string
    collectionName: ReactNode
    isRarityDisplayed: boolean
    rarityContentDataKey: RarityIndicator_data$key | null
    shouldShowBestAsk: boolean
    bestAsk?: ItemCardFooter_bestAskV2$data | null
    item: ItemCardFooter$data | null
    owner: ItemCardFooter$data["largestOwner"]
  }) => ReactElement | null
}

export function useTransactionStatusText() {
  const t = useTranslate("components")
  return {
    pendingText: t("transactionIndicators.pending", "View pending transaction"),
    completeText: t("transactionIndicators.complete", "Transaction complete"),
  }
}

const ItemCardFooterBase = React.memo(function ItemCardFooterBase({
  item: itemKey,
  disabled = false,
  accountAddress,
  showCollectionName = true,
  variant,
  children,
}: Props) {
  const router = useRouter()
  const item = useFragment(
    /* eslint-disable relay/unused-fields */
    graphql`
      fragment ItemCardFooter on ItemType
      @argumentDefinitions(
        identity: { type: "IdentityInputType" }
        shouldShowBestBid: { type: "Boolean", defaultValue: false }
        showBestAskForOwner: { type: "Boolean", defaultValue: false }
        showContextMenu: { type: "Boolean", defaultValue: false }
      ) {
        __typename
        relayId
        name
        orderData {
          bestBidV2 {
            orderType
            priceType {
              unit
            }
            ...ItemCardPrice_data
          }
          bestAskV2 {
            ...ItemCardFooter_bestAskV2
          }
          # Only request bestAskForOwnerItemCard if showBestAskForOwner is true
          # (on account page) since it prevents caching. Different name than
          # bestAskForOwner since this is conditionally requested with
          # showBestAskForOwner and breaks fragments that use the same name.
          bestAskForOwnerItemCard: bestAskV2(byAddress: $identity)
            @include(if: $showBestAskForOwner) {
            ...ItemCardFooter_bestAskV2
          }
        }
        ...ItemMetadata
          @arguments(
            identity: $identity
            showBestAskForOwner: $showBestAskForOwner
            shouldShowBestBid: $shouldShowBestBid
          )
        saleOrListingCancellationOccurred
        pendingTxn {
          status
          maxFeeGwei
          priorityFeeGwei
          seenAt
          transactionHash
          blockExplorerLink
        }
        ... on AssetType {
          tokenId
          isDelisted
          defaultRarityData {
            ...RarityIndicator_data
          }
          collection {
            slug
            name
            isVerified
            ...collection_url
            ...useIsRarityEnabled_collection
          }
          largestOwner {
            owner {
              # eslint-disable-next-line relay/must-colocate-fragment-spreads
              ...AccountLink_data
            }
          }
          # eslint-disable-next-line relay/must-colocate-fragment-spreads
          ...AssetSearchListViewTableAssetInfo_item
        }
        ... on AssetBundleType {
          bundleCollection: collection {
            slug
            name
            isVerified
            ...collection_url
            ...useIsRarityEnabled_collection
          }
        }
        ...useItemCardCta_item
          @arguments(identity: $identity, showContextMenu: $showContextMenu)
        # TODO: Remove these once the table view is cleaned up
        # eslint-disable-next-line relay/must-colocate-fragment-spreads
        ...item_url
        # eslint-disable-next-line relay/must-colocate-fragment-spreads
        ...ItemCardContent
      }
    `,
    itemKey,
  )

  const ownerListingPriceEnabled = useOwnerListingPriceOnAccountPage()
  const [canceledListingsItemRelayIds] = useCanceledListingsItemRelayIds()
  const bestAsk = useFragment<ItemCardFooter_bestAskV2$key>(
    graphql`
      fragment ItemCardFooter_bestAskV2 on OrderV2Type {
        orderType
        insertedAt
        priceType {
          unit
        }
        maker {
          address
        }
        ...ItemCardPrice_data
        # eslint-disable-next-line relay/must-colocate-fragment-spreads
        ...ItemAddToCartButton_order
        # eslint-disable-next-line relay/must-colocate-fragment-spreads
        ...AssetSearchListViewTableQuickBuy_order
        # eslint-disable-next-line relay/must-colocate-fragment-spreads
        ...useIsQuickBuyEnabled_order
      }
    `,
    item && !canceledListingsItemRelayIds.includes(item.relayId)
      ? (ownerListingPriceEnabled && item.orderData.bestAskForOwnerItemCard
          ? item.orderData.bestAskForOwnerItemCard
          : item.orderData.bestAskV2) ?? null
      : null,
  )

  const { pathname } = useRouter()

  const isSmallScreen = useIsLessThanSm()
  const isMdScreen = useIsLessThanLg()
  const onCollectionPage = pathname ? pathname.includes("/collection/") : false
  const shouldOmitCollectionName = onCollectionPage || !showCollectionName

  const { isCtaAvailable } = useItemCardCta(item)

  const orderData = item?.orderData
  const bestBid = orderData?.bestBidV2
  const shouldShowBestAsk =
    !accountAddress || accountAddress === bestAsk?.maker.address

  const isLiveUpdatesEnabledForCollection =
    useIsLiveUpdatesEnabledForCollection(null)
  const animatePendingTxn = Boolean(
    isLiveUpdatesEnabledForCollection &&
      item?.pendingTxn &&
      item.pendingTxn.status &&
      !SHOULD_NOT_ANIMATE_TRANSACTION_STATUS.includes(item.pendingTxn.status),
  )
  const animateSaleEvent =
    isLiveUpdatesEnabledForCollection && item?.saleOrListingCancellationOccurred

  const {
    maxFeeGwei,
    priorityFeeGwei,
    seenAt,
    transactionHash,
    blockExplorerLink,
  } = item?.pendingTxn ?? {}

  const { completeText, pendingText } = useTransactionStatusText()

  const renderLiveUpdatesIcon = () => {
    return animateSaleEvent ? (
      <Tooltip content={completeText}>
        <Checkmark />
      </Tooltip>
    ) : animatePendingTxn ? (
      <Tooltip
        content={
          <ItemCardPendingTxnTooltip
            blockExplorerLink={blockExplorerLink}
            maxFeeGwei={maxFeeGwei}
            priorityFeeGwei={priorityFeeGwei}
            seenAt={seenAt ? dateFromISO8601(seenAt) : null}
            transactionHash={transactionHash}
          />
        }
        interactive
      >
        <UnstyledButton
          aria-label={pendingText}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            blockExplorerLink && window.open(blockExplorerLink, "_blank")
          }}
        >
          <Spinner size="xsmall" />
        </UnstyledButton>
      </Tooltip>
    ) : null
  }

  const renderPrice = useCallback(
    (
      { noTooltip = false }: Partial<ComponentProps<typeof ItemCardPrice>> = {},
      noBottomMargin = false,
      noRightPadding = false,
    ) => {
      const listContext = variant === "list-view"
      let textSize: ComponentProps<typeof ItemCardPrice>["size"] = "small"
      if (isMdScreen && listContext) {
        textSize = "tiny"
      }
      if (!isMdScreen && !listContext) {
        textSize = "medium"
      }

      if (item?.isDelisted) {
        return null
      }

      // Price for English Auctions
      if (bestAsk?.orderType === "ENGLISH") {
        const shouldShowTopBid =
          bestBid && bn(bestBid.priceType.unit).gte(bn(bestAsk.priceType.unit))

        return (
          <ItemCardPriceContainer
            data={shouldShowTopBid ? bestBid : bestAsk}
            listContext={listContext}
            size={textSize}
          />
        )
      }

      // Price for Dutch Auctions AND direct sales (special case w/ initial === final)
      if (bestAsk) {
        if (!shouldShowBestAsk) {
          return null
        }

        return (
          <ItemCardPriceContainer
            $noBottomMargin={noBottomMargin}
            $noRightPadding={noRightPadding}
            compactDisplay
            data={bestAsk}
            listContext={listContext}
            noTooltip={noTooltip}
            size={textSize}
          />
        )
      }

      return null
    },
    [
      bestAsk,
      bestBid,
      isMdScreen,
      item?.isDelisted,
      shouldShowBestAsk,
      variant,
    ],
  )

  const collection = item?.bundleCollection ?? item?.collection

  const isRarityDisplayed = useIsRarityEnabled(collection ?? null)

  const assetTitle =
    item?.name || (item?.tokenId && getTokenIdSuffix(item.tokenId))

  const collectionName =
    !shouldOmitCollectionName || variant === "list-view" ? (
      <Flex className="h-5 w-4/5">
        {collection && (
          <Block maxWidth="100%">
            <ItemCardCollectionName
              role="link"
              tabIndex={0}
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                router.push(getCollectionUrl(collection))
              }}
              onKeyDown={e => {
                e.preventDefault()
                if (e.key === "Enter") {
                  router.push(getCollectionUrl(collection))
                }
              }}
            >
              <CollectionTooltip placement="left" slug={collection.slug}>
                <Flex className="items-center">
                  <NameContainer>
                    <CollectionName className="text-primary">
                      {collection.name}
                    </CollectionName>
                  </NameContainer>
                  {collection.isVerified && (
                    <IconContainer aria-hidden="true">
                      <StyledVerifiedIcon size="small" />
                    </IconContainer>
                  )}
                </Flex>
              </CollectionTooltip>
            </ItemCardCollectionName>
          </Block>
        )}
      </Flex>
    ) : null

  if (variant === "list-view" && children) {
    return children({
      renderPrice,
      title: assetTitle,
      collectionName,
      isRarityDisplayed,
      rarityContentDataKey: item?.defaultRarityData ?? null,
      shouldShowBestAsk,
      bestAsk,
      item,
      owner: item?.largestOwner ?? null,
    })
  }

  return (
    <StyledItemCardFooter
      $disabled={disabled}
      $shouldOmitCollectionName={shouldOmitCollectionName}
      padding={16}
    >
      <Block>
        <Flex className="items-center justify-between">
          <OverflowTooltipNameContainer
            overrides={{ Tooltip: { hideOnScroll: true } }}
            preventClickThrough={isSmallScreen}
          >
            <Text.Body
              className="text-primary"
              data-testid="ItemCardFooter-name"
              size="small"
              weight="semibold"
            >
              {assetTitle}
            </Text.Body>
          </OverflowTooltipNameContainer>
          {isRarityDisplayed && item?.defaultRarityData && (
            <Block flexShrink={0} marginLeft="8px">
              <RarityIndicator
                compact={isSmallScreen || variant === "compact"}
                dataKey={item.defaultRarityData}
              />
            </Block>
          )}
        </Flex>
        {collectionName}
      </Block>

      <FlexColumn className="mt-2 w-full">
        <Flex className="mb-2 h-5 w-full">
          {renderPrice()}
          {renderLiveUpdatesIcon()}
        </Flex>
        <Flex className="h-5 items-center">
          <Block
            as={isCtaAvailable ? ItemCardMetadataContainer : undefined}
            className="w-full"
          >
            <FlexStart className="w-full">
              <ItemMetadata item={item} />
            </FlexStart>
          </Block>
        </Flex>
      </FlexColumn>
    </StyledItemCardFooter>
  )
})

const ItemCardFooterSkeleton = memo(function ItemCardFooterSkeleton() {
  const { pathname } = useRouter()
  const onCollectionPage = pathname ? pathname.includes("/collection/") : false

  return (
    <StyledItemCardFooter $shouldOmitCollectionName={onCollectionPage}>
      <Skeleton className="p-4">
        <Skeleton.Row>
          <Skeleton.Line className="h-4 w-24" />
        </Skeleton.Row>
        {!onCollectionPage && (
          <StyledSkeletonRow>
            <Skeleton.Line className="h-4 w-24" />
          </StyledSkeletonRow>
        )}
        <PriceSkeletonRow>
          <PriceSkeletonLine />
        </PriceSkeletonRow>
        <ItemCardCta.Skeleton />
      </Skeleton>
    </StyledItemCardFooter>
  )
})

const StyledSkeletonRow = styled(Skeleton.Row)`
  && {
    margin-top: 4px;
  }
`

const PriceSkeletonRow = styled(Skeleton.Row)`
  && {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`

const PriceSkeletonLine = styled(Skeleton.Line)`
  height: 16px;
  width: 96px;

  @media (min-width: ${breakpoints.lg}px) {
    height: 24px;
    width: 80px;
  }
`

const StyledItemCardFooter = styled(Block)<{
  $disabled?: boolean
  $shouldOmitCollectionName?: boolean
}>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${props =>
    props.$disabled &&
    css`
      opacity: 0.3;
    `}
`

type NameContainerProps = {
  fontWeight?: number
  fontSize?: CSSProperties["fontSize"]
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
}

const NameContainerStyles = css<NameContainerProps>`
  display: block;
  height: 100%;
  font-weight: ${props => props.fontWeight};
  font-size: ${props => props.fontSize};

  ${props =>
    themeVariant({
      variants: {
        dark: {
          color: props.theme.colors.white,
        },
        light: {
          color: props.theme.colors.charcoal,
        },
      },
    })}
`

const NameContainer = styled(OverflowContainer)<NameContainerProps>`
  ${NameContainerStyles}
`

const CollectionName = styled(Text.Body).attrs({
  size: "small",
  weight: "semibold",
})`
  transition: color 0.25s ease-in-out;
  :hover {
    color: ${props => props.theme.colors.text.secondary};
  }
`

const OverflowTooltipNameContainer = styled(Overflow)<NameContainerProps>`
  ${NameContainerStyles}
`

const ItemCardCollectionName = styled(Block)`
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;

  &:active,
  &:focus {
    outline: 1px solid ${props => props.theme.colors.components.border.level2};
  }
`

const ItemCardPriceContainer = styled(ItemCardPrice)<{
  $noBottomMargin?: boolean
  $noRightPadding?: boolean
}>`
  padding-right: ${props => (props.$noRightPadding ? "0px" : "8px")};
  margin-bottom: ${props => (props.$noBottomMargin ? "0px" : "-4px")};
`

const IconContainer = styled(Flex)`
  width: 14px;
  height: 14px;
  margin-left: 2px;
`

const StyledVerifiedIcon = styled(VerifiedIcon)`
  width: 15px;
  height: 15px;
`

export const ItemCardMetadataContainer = styled(Block)``

export const ItemCardFooter = Object.assign(ItemCardFooterBase, {
  Skeleton: ItemCardFooterSkeleton,
})
