import React from "react"
import { Flex, TextBodyProps } from "@opensea/ui-kit"
import { identity } from "lodash"
import { graphql, useFragment } from "react-relay"
import { useIsQuickBuyEnabled } from "@/components/trade/QuickBuyButton"
import { Block } from "@/design-system/Block"
import { useTheme } from "@/design-system/Context"
import { PriceText } from "@/design-system/PriceText"
import { PriceTicker } from "@/design-system/PriceTicker"
import { useIsLiveUpdatesEnabledForCollection } from "@/features/live-updates/hooks/useIsLiveUpdatesEnabledForCollection"
import { ItemCardPrice_data$key } from "@/lib/graphql/__generated__/ItemCardPrice_data.graphql"
import { bn } from "@/lib/helpers/numberUtils"
import THEMES from "@/styles/themes"

type Props = {
  data: ItemCardPrice_data$key
  noTooltip?: boolean
  compactDisplay?: boolean
  listContext?: boolean
} & Pick<TextBodyProps, "size">

export const ItemCardPrice = ({
  data: dataKey,
  noTooltip,
  compactDisplay,
  listContext,
}: Props) => {
  const { theme } = useTheme()
  const data = useFragment(
    graphql`
      fragment ItemCardPrice_data on OrderV2Type {
        perUnitPriceType {
          unit
        }
        payment {
          symbol
        }
        # eslint-disable-next-line relay/must-colocate-fragment-spreads
        ...useIsQuickBuyEnabled_order
      }
    `,
    dataKey,
  )

  const { perUnitPriceType, payment } = data
  const { symbol } = payment

  const currentPrice = bn(perUnitPriceType.unit)

  const price = identity(currentPrice)

  const isLiveUpdatesEnabledForCollection =
    useIsLiveUpdatesEnabledForCollection(null)

  const isQuickBuyEnabled = useIsQuickBuyEnabled(data)

  const listContextProps =
    listContext && isQuickBuyEnabled
      ? {
          borderRadius: THEMES[theme].borderRadius.button,
          margin: "-7px -31px -7px -9px",
          padding: "7px 31px 7px 9px",
        }
      : {}

  const priceDisplay = isLiveUpdatesEnabledForCollection ? (
    symbol === "usd" ? (
      <PriceTicker.Fiat
        compactDisplay={compactDisplay}
        noTooltip={noTooltip}
        usd={price}
        weight="semibold"
        {...listContextProps}
      />
    ) : (
      <PriceTicker.Token
        compactDisplay={compactDisplay}
        noTooltip={noTooltip}
        symbol={symbol}
        unit={price}
        weight="semibold"
        {...listContextProps}
      />
    )
  ) : symbol === "usd" ? (
    <PriceText.Fiat
      compactDisplay={compactDisplay}
      usd={price}
      weight="semibold"
    />
  ) : (
    <PriceText.Token
      compactDisplay={compactDisplay}
      symbol={symbol}
      unit={price}
      weight="semibold"
    />
  )

  return (
    <Block data-testid="ItemCardPrice" marginRight="4px" maxWidth="fit-content">
      <Flex className="items-center">{priceDisplay}</Flex>
    </Block>
  )
}
