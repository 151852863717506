/* eslint-disable tailwindcss/no-custom-classname */
import React, { forwardRef } from "react"
import { Icon, MaterialIcon, Text } from "@opensea/ui-kit"
import styled from "styled-components"
import { variant } from "styled-system"
import { BlockProps, Block } from "@/design-system/Block"
import { Image } from "@/design-system/Image"
import { Values } from "../../lib/helpers/type"
import { generateVariants } from "../../styles/styleUtils"

export type BadgeProps = {
  text?: string | React.ReactNode
  icon?: MaterialIcon
  imageUrl?: string
  iconSize?: number
  className?: string
  variant?: BadgeVariant
} & BlockProps

export const BADGE_VARIANTS = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  TERTIARY: "tertiary",
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
} as const

type BadgeVariant = Values<typeof BADGE_VARIANTS>

export const Badge = forwardRef<HTMLDivElement, BadgeProps>(function Badge(
  { text, icon, iconSize = 14, className, variant, imageUrl, ...rest },
  ref,
) {
  return (
    <DivContainer className={className} ref={ref} variant={variant} {...rest}>
      {imageUrl ? (
        <Image
          alt="Verified badge"
          height={iconSize}
          src={imageUrl}
          width={iconSize}
        />
      ) : null}
      {icon ? (
        <Icon className="Badge--icon" size={iconSize} value={icon} />
      ) : null}
      {text ? (
        <Text className="Badge--text" size="small">
          {text}
        </Text>
      ) : null}
    </DivContainer>
  )
})

const DivContainer = styled(Block)<{ variant?: BadgeVariant }>`
  width: fit-content;
  border-radius: 4px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: ${props => props.theme.colors.components.border.level2};
  color: ${props => props.theme.colors.text.secondary};

  .Badge--icon {
    margin-right: 4px;
  }

  .Badge--text {
    ${props =>
      variant({
        variants: generateVariants(BADGE_VARIANTS, variant => ({
          color: props.theme.colors.text.on[variant],
        })),
      })}
  }

  ${props =>
    variant({
      variants: generateVariants(BADGE_VARIANTS, variant => ({
        backgroundColor: props.theme.colors[variant],
        color: props.theme.colors.text.on[variant],
      })),
    })}
`
