import { createContext } from "react"
import { noop } from "lodash"
import { NextPageContext } from "next/types"
import { getCardVariantFromCookie } from "@/components/search/assets/AssetSearchView"
import { AccountTab } from "@/features/account/types"
import { resolveProfileIdentity } from "@/features/account/utils/identity"
import { Search } from "@/hooks/useSearch"
import Router from "@/lib/helpers/router"

type Props = {
  context: NextPageContext
  collectionSlug?: string
  identifier?: string
  search?: Search
  tab?: AccountTab
  tableViewSupported?: boolean
}

const FALLBACK_SORT_BY = "LAST_TRANSFER_DATE"
const FALLBACK_ORDER_SORT_BY = "OPENED_AT"

export const getInitialPropsAccountPageQuery = async ({
  context,
  identifier,
  search,
  tab,
  tableViewSupported = false,
}: Props) => {
  const { identity, isCurrentUser } = await resolveProfileIdentity(
    context,
    identifier,
  )

  const path = Router.getPathname(context)
  const pathParts = path.split("/")

  const topLevelPath = pathParts[1]
  const isYourProfile = topLevelPath === "account"

  // We explicitly do not want to include the collection slug in the path
  // Examples:
  // 1. opensea.io/<address>/collected/<collection> should be opensea.io/<address>/collected as the realPath
  // 2. opensea.io/account/collected/<collection> should be opensea.io/account/collected as the realPath
  // 3. opensea.io/account/collection should be opensea.io/account as the realPath
  const lastIndexOfTabName = pathParts.lastIndexOf(tab ?? "account")

  const realPath = pathParts.slice(0, lastIndexOfTabName + 1).join("/")

  // read list_view_supported if it exists, otherwise read account one.
  const generalCardVariantFromCookie = getCardVariantFromCookie(
    "account",
    context,
  )
  const cardVariantFromCookie = tableViewSupported
    ? getCardVariantFromCookie("account_table_view_supported", context) ??
      generalCardVariantFromCookie
    : generalCardVariantFromCookie

  const sortBy = search?.sortBy ?? FALLBACK_SORT_BY
  const orderSortBy = search?.orderSortBy ?? FALLBACK_ORDER_SORT_BY

  const collectionSlug =
    search?.collections?.length === 1 ? search.collections[0] : ""

  return {
    identifier,
    // TODO(@laurafiuza): It's confusing that we need both
    // isYourProfile and isCurrentUser - perhaps consolidate if applicable?
    isCurrentUser,
    isYourProfile,
    path: realPath,
    assetCardVariant: cardVariantFromCookie,
    sortBy,
    orderSortBy,
    sortAscending: search?.sortAscending,
    variables: {
      includePrivateAssetCount: isCurrentUser,
      identity,
      chains: search?.chains,
      collection: collectionSlug,
      collections:
        search?.collections ?? (collectionSlug ? [collectionSlug] : []),
    },
  }
}

export const cachePolicy = {
  maxAge: 30,
}

// We use to hide locally hide assets that are hidden/unhidden without having
// to refetch the whole connection. We do it this way because doing Relay
// local state update for that connection seems to be impossible
// TODO: revisit in future and see if we can update that directly from the mutation's updater fn
type AccountPageContext = {
  exclude: string[]
  setExclude: (exclude: string[]) => unknown
}

const DEFAULT_CONTEXT: AccountPageContext = {
  exclude: [],
  setExclude: noop,
}

export const AccountPageContext = createContext(DEFAULT_CONTEXT)
