/**
 * @generated SignedSource<<896997e180fd41ef5d3872c4ceab23f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type IdentityInputType = {
  address?: string | null;
  name?: string | null;
  username?: string | null;
};
export type CollectionSelectQuery$variables = {
  editor?: IdentityInputType | null;
  onlySharedStorefront?: boolean | null;
  query?: string | null;
};
export type CollectionSelectQuery$data = {
  readonly collections: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly defaultMintableAssetContract: {
          readonly address: string;
          readonly openseaVersion: string | null;
          readonly relayId: string;
        } | null;
        readonly imageUrl: string | null;
        readonly name: string;
        readonly slug: string;
      } | null;
    } | null>;
  };
};
export type CollectionSelectQuery = {
  response: CollectionSelectQuery$data;
  variables: CollectionSelectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "editor"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "onlySharedStorefront"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v3 = [
  {
    "kind": "Variable",
    "name": "editor",
    "variableName": "editor"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 25
  },
  {
    "kind": "Variable",
    "name": "onlySharedStorefront",
    "variableName": "onlySharedStorefront"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  },
  {
    "kind": "Literal",
    "name": "sortBy",
    "value": "CREATED_DATE"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "openseaVersion",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CollectionSelectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "CollectionTypeConnection",
        "kind": "LinkedField",
        "name": "collections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssetContractType",
                    "kind": "LinkedField",
                    "name": "defaultMintableAssetContract",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CollectionSelectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "CollectionTypeConnection",
        "kind": "LinkedField",
        "name": "collections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssetContractType",
                    "kind": "LinkedField",
                    "name": "defaultMintableAssetContract",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cf8e368d4ca9b6a87c56cb9732d33b76",
    "id": null,
    "metadata": {},
    "name": "CollectionSelectQuery",
    "operationKind": "query",
    "text": "query CollectionSelectQuery(\n  $query: String\n  $editor: IdentityInputType\n  $onlySharedStorefront: Boolean\n) {\n  collections(editor: $editor, first: 25, sortBy: CREATED_DATE, query: $query, onlySharedStorefront: $onlySharedStorefront) {\n    edges {\n      node {\n        name\n        imageUrl\n        slug\n        defaultMintableAssetContract {\n          address\n          relayId\n          openseaVersion\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c65d3cb7c90e04b06e670a10c8c7f6a8";

export default node;
