import React, { useCallback } from "react"
import {
  AsyncSelect,
  AsyncSelectProps,
} from "@/design-system/AsyncSelect/AsyncSelect.react"
import { Avatar } from "@/design-system/Avatar"
import type { SelectOption } from "@/design-system/Select"
import { useTranslate } from "@/hooks/useTranslate"
import type { IdentityKey } from "@/lib/auth/types"
import {
  CollectionSelectQuery,
  CollectionSelectQuery$data,
} from "@/lib/graphql/__generated__/CollectionSelectQuery.graphql"
import { fetch, getNodes, graphql, Node } from "@/lib/graphql/graphql"

export type CollectionOption = SelectOption<string> &
  Pick<
    Node<CollectionSelectQuery$data["collections"]>,
    "defaultMintableAssetContract"
  >

export type CollectionSelectProps = Pick<
  AsyncSelectProps,
  "id" | "name" | "placeholder"
> & {
  value?: CollectionOption
  onSelect: (option: CollectionOption | undefined) => unknown
  editor?: IdentityKey
  onlySharedStorefront?: boolean
}

export const CollectionSelect = ({
  value,
  editor,
  placeholder,
  onlySharedStorefront = false,
  ...rest
}: CollectionSelectProps) => {
  const t = useTranslate("components")
  const search = useCallback(
    async (query: string) => {
      const [{ collections }] = await fetch<CollectionSelectQuery>(
        graphql`
          query CollectionSelectQuery(
            $query: String
            $editor: IdentityInputType
            $onlySharedStorefront: Boolean
          ) {
            collections(
              editor: $editor
              first: 25
              sortBy: CREATED_DATE
              query: $query
              onlySharedStorefront: $onlySharedStorefront
            ) {
              edges {
                node {
                  name
                  imageUrl
                  slug
                  defaultMintableAssetContract {
                    address
                    relayId
                    openseaVersion
                  }
                }
              }
            }
          }
        `,
        { editor, query, onlySharedStorefront },
      )

      return getNodes(collections).map(
        ({ name, slug, imageUrl, defaultMintableAssetContract }) => ({
          label: name,
          value: slug,
          avatar: imageUrl
            ? { src: imageUrl, rounded: true, size: 32 }
            : undefined,
          defaultMintableAssetContract,
        }),
      )
    },
    [editor, onlySharedStorefront],
  )

  return (
    <AsyncSelect
      clearable={false}
      loadingConfiguration={{ avatar: true, description: true }}
      maxHeight="295px"
      placeholder={
        placeholder ??
        t("forms.collectionSelect.placeholder", "Select collection")
      }
      search={search}
      startEnhancer={
        value ? <Avatar {...value.avatar} rounded size={24} /> : null
      }
      value={value?.label}
      {...rest}
    />
  )
}
