import React from "react"
import { Text } from "@opensea/ui-kit"
import { Controller, useForm } from "react-hook-form"
import {
  CollectionSelect,
  CollectionOption,
} from "@/components/forms/CollectionSelect"
import { useActiveIdentity } from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { Modal } from "@/design-system/Modal"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { trackMoveAssetCollection } from "@/lib/analytics/events/collectionEvents"
import type { AssetChangeCollectionModalMutation } from "@/lib/graphql/__generated__/AssetChangeCollectionModalMutation.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"

type Props = {
  assets: string[]
  isSharedStorefront?: boolean
  onSuccess: () => unknown
}

type UseFormData = {
  collection: { value: string; label: string }
}

export const AssetChangeCollectionModal = ({
  onSuccess,
  assets,
  isSharedStorefront,
}: Props) => {
  const t = useTranslate("collections")
  const { mutate } = useGraphQL()
  const { attempt, showSuccessMessage } = useToasts()
  const numItems = assets.length
  const editor = useActiveIdentity()

  const { handleSubmit, control, formState } = useForm<UseFormData>({
    mode: "onChange",
  })

  const onSubmit = handleSubmit(async ({ collection }) => {
    trackMoveAssetCollection()

    await attempt(async () => {
      await mutate<AssetChangeCollectionModalMutation>(
        graphql`
          mutation AssetChangeCollectionModalMutation(
            $assets: [AssetRelayID!]!
            $collection: CollectionSlug!
          ) {
            assets {
              changeCollection(assets: $assets, collection: $collection) {
                relayId
                collection {
                  name
                  slug
                  displayData {
                    cardDisplayStyle
                  }
                  defaultChain {
                    identifier
                  }
                  enabledRarities
                }
              }
            }
          }
        `,
        { assets, collection: collection.value },
        {
          shouldAuthenticate: true,
          updater: (store, { assets }) => {
            assets.changeCollection.forEach(({ relayId, collection }) => {
              // Another one that makes no sense to me
              const assetRecord = store.get(relayId)
              const assetCollection = assetRecord?.getLinkedRecord("collection")

              if (assetCollection) {
                assetCollection.setValue(collection.name, "name")
                assetCollection.setValue(collection.slug, "slug")
                const collectionDisplayData =
                  assetCollection.getOrCreateLinkedRecord(
                    "displayData",
                    "CardDisplayStyle",
                  )
                collectionDisplayData.setValue(
                  collection.displayData.cardDisplayStyle,
                  "cardDisplayStyle",
                )
                const defaultChain = assetCollection.getOrCreateLinkedRecord(
                  "defaultChain",
                  "ChainType",
                )
                defaultChain.setValue(
                  collection.defaultChain.identifier,
                  "identifier",
                )
                assetCollection.setValue(
                  collection.enabledRarities as string[],
                  "enabledRarities",
                )
              }
            })
          },
        },
      )

      showSuccessMessage(
        t(
          "change.successMessage",
          {
            0: "Successfully moved {{count}} items to {{label}}",
            one: "Successfully moved {{count}} item to {{label}}",
            other: "Successfully moved {{count}} items to {{label}}",
          },
          { count: numItems, label: collection.label },
          { forceString: true },
        ),
      )

      onSuccess()
    })
  })

  return (
    <Modal.Form onSubmit={onSubmit}>
      <Modal.Header>
        {/* LITERAL_STRING (see https://www.notion.so/opensea/ef12134ca3ae409c91a2de063e8ea731) */}
        <Modal.Header.Title>Move to new collection</Modal.Header.Title>
      </Modal.Header>

      <Modal.Body>
        <Block marginBottom="4px">
          {/* LITERAL_STRING (see https://www.notion.so/opensea/ef12134ca3ae409c91a2de063e8ea731) */}
          <label htmlFor="collection" style={{ fontWeight: 600 }}>
            Collection
          </label>
        </Block>

        <Controller
          control={control}
          name="collection"
          render={({ field }) => {
            const { ref: _ref, onChange, value, ...rest } = field
            return (
              <CollectionSelect
                {...rest}
                editor={editor}
                id={field.name}
                onlySharedStorefront={isSharedStorefront}
                value={value as CollectionOption}
                onSelect={onChange}
              />
            )
          }}
          rules={{ required: true }}
        />

        <Block marginTop="16px">
          <Text size="small">
            {t(
              "change.moveDelay",
              "Moving items to a different collection may take up to 30 minutes.",
            )}
          </Text>
        </Block>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button
          disabled={!formState.isValid}
          isLoading={formState.isSubmitting}
          type="submit"
        >
          {t(
            "change.move",
            {
              "0": "Move {{numItems}} items",
              one: "Move",
              other: "Move {{numItems}} items",
            },
            { count: numItems, numItems },
          )}
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal.Form>
  )
}
