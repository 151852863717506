import React, { memo, useState } from "react"
import {
  Icon,
  useIsGreaterThanOrEqualToLg,
  Text,
  VerticalAligned,
  Skeleton,
} from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled, { css } from "styled-components"
import { AssetContextMenu } from "@/components/common/AssetContextMenu"
import { CreateListingButton } from "@/components/orders/CreateListingButton"
import { EditListingButton } from "@/components/orders/EditListingButton.react"
import { AddToCartAndQuickBuyButton } from "@/components/trade/AddToCartAndQuickBuyButton"
import { QuickBuyButton } from "@/components/trade/QuickBuyButton"
import { Tooltip } from "@/design-system/Tooltip"
import { useTranslate } from "@/hooks/useTranslate"
import { trackOpenItemCardContextMenu } from "@/lib/analytics/events/itemEvents"
import { ItemCardCta_item$key } from "@/lib/graphql/__generated__/ItemCardCta_item.graphql"
import { graphql } from "@/lib/graphql/graphql"
import {
  ITEM_CARD_ADD_TO_CART_AND_QUICK_BUY_BUTTON_OVERRIDES,
  ITEM_CARD_CTA_TRANSITION_DURATION,
} from "./constants"
import {
  ItemCardCtaButtonContainer,
  ItemCardFullWidthCtaButton,
  ItemCardFullWidthCtaButtonCss,
  ItemCardMobileFullWidthCtaButton,
  ItemCardPrimaryCtaButton,
  ItemCardPrimaryCtaButtonCss,
  ItemCardSecondaryCtaButton,
} from "./elements"
import { useItemCardCta } from "./useItemCardCta"

type ItemCardCtaProps = {
  item: ItemCardCta_item$key | null
  showContextMenu?: boolean
  toggleAssetSelected: () => void
}

const ItemCardCtaBase = React.memo(function ItemCardCtaBase({
  item: itemKey,
  showContextMenu,
  toggleAssetSelected,
}: ItemCardCtaProps) {
  const t = useTranslate("components")
  const isDesktop = useIsGreaterThanOrEqualToLg()

  const item = useFragment(
    graphql`
      fragment ItemCardCta_item on ItemType
      @argumentDefinitions(
        showContextMenu: { type: "Boolean", defaultValue: false }
        identity: { type: "IdentityInputType" }
      ) {
        __typename
        orderData {
          bestAskV2 {
            ...AddToCartAndQuickBuyButton_order
            ...EditListingButton_listing
            ...QuickBuyButton_order
          }
        }
        ...AssetContextMenu_data @include(if: $showContextMenu)
        ...useItemCardCta_item
          @arguments(identity: $identity, showContextMenu: $showContextMenu)
        ...itemEvents_dataV2
        ...CreateListingButton_item
        ...EditListingButton_item
      }
    `,
    itemKey,
  )

  const {
    isListForSaleAvailable,
    isEditListingAvailable,
    isContextMenuAvailable,
    isAddToCartAvailable,
    isBuyNowAvailable,
    isCtaAvailable,
  } = useItemCardCta(item, {
    showContextMenu,
  })

  const [isAssetContextMenuOpen, setIsAssetContextMenuOpen] = useState(false)

  if (!isCtaAvailable || !item) {
    return null
  }

  const listForSaleText = t("assets.card.cta.listForSale", "List for sale")

  const renderAssetContextMenuCtaButton = (
    ButtonComponent:
      | typeof ItemCardSecondaryCtaButton
      | typeof ItemCardSecondaryCtaButton
      | typeof ItemCardMobileFullWidthCtaButton,
  ) => {
    if (item.__typename !== "AssetType") {
      return null
    }

    return (
      <AssetContextMenu
        dataKey={item}
        source="item card"
        toggleAssetSelected={toggleAssetSelected}
        onHide={() => setIsAssetContextMenuOpen(false)}
        onTrigger={() => {
          trackOpenItemCardContextMenu(item)
          setIsAssetContextMenuOpen(true)
        }}
      >
        <Tooltip
          content={t("assets.card.more.tooltip", "More options")}
          delay={[
            ITEM_CARD_CTA_TRANSITION_DURATION,
            ITEM_CARD_CTA_TRANSITION_DURATION * 2,
          ]}
          disabled={isAssetContextMenuOpen}
          placement="top-end"
        >
          <ButtonComponent
            aria-label={t("assets.card.more.label", "More")}
            icon={
              <Icon
                className={isDesktop ? "text-white" : "text-secondary"}
                value="more_horiz"
              />
            }
            onClick={event => {
              event.stopPropagation()
              event.preventDefault()
            }}
          />
        </Tooltip>
      </AssetContextMenu>
    )
  }

  return (
    <ItemCardCtaContainer
      active={
        isAssetContextMenuOpen ||
        // Always show CTA on mobile when context menu is available
        (isContextMenuAvailable && !isDesktop)
      }
    >
      {isContextMenuAvailable && !isDesktop ? (
        renderAssetContextMenuCtaButton(ItemCardMobileFullWidthCtaButton)
      ) : isListForSaleAvailable && isContextMenuAvailable ? (
        <ItemCardCtaButtonContainer>
          <CreateListingButton
            item={item}
            source="item card"
            trigger={onClick => (
              <ItemCardPrimaryCtaButton onClick={onClick}>
                <Text.Body size="small" weight="semibold">
                  {listForSaleText}
                </Text.Body>
              </ItemCardPrimaryCtaButton>
            )}
          />
          {renderAssetContextMenuCtaButton(ItemCardSecondaryCtaButton)}
        </ItemCardCtaButtonContainer>
      ) : isListForSaleAvailable ? (
        <CreateListingButton
          item={item}
          source="item card"
          trigger={onClick => (
            <ItemCardFullWidthCtaButton onClick={onClick}>
              <Text.Body size="small" weight="semibold">
                {listForSaleText}
              </Text.Body>
            </ItemCardFullWidthCtaButton>
          )}
        />
      ) : isEditListingAvailable &&
        isContextMenuAvailable &&
        item.orderData.bestAskV2 ? (
        <ItemCardCtaButtonContainer>
          <ItemCardEditListingPrimaryButton
            item={item}
            listing={item.orderData.bestAskV2}
            source="item card"
          />
          {renderAssetContextMenuCtaButton(ItemCardSecondaryCtaButton)}
        </ItemCardCtaButtonContainer>
      ) : isEditListingAvailable && item.orderData.bestAskV2 ? (
        <ItemCardEditListingFullWidthButton
          item={item}
          listing={item.orderData.bestAskV2}
          source="item card"
        />
      ) : isContextMenuAvailable ? (
        renderAssetContextMenuCtaButton(ItemCardFullWidthCtaButton)
      ) : isAddToCartAvailable && item.orderData.bestAskV2 ? (
        <AddToCartAndQuickBuyButton
          className="ml-[-1px] rounded-b-default rounded-t-none"
          order={item.orderData.bestAskV2}
          overrides={ITEM_CARD_ADD_TO_CART_AND_QUICK_BUY_BUTTON_OVERRIDES}
        />
      ) : isBuyNowAvailable && item.orderData.bestAskV2 ? (
        <QuickBuyButton
          order={item.orderData.bestAskV2}
          overrides={
            ITEM_CARD_ADD_TO_CART_AND_QUICK_BUY_BUTTON_OVERRIDES.QuickBuyButton
              .props.overrides
          }
        />
      ) : null}
    </ItemCardCtaContainer>
  )
})

const ItemCardCtaSkeleton = memo(function ItemCardCtaSkeleton() {
  return (
    <Skeleton.Row className="mt-1">
      <Skeleton.Line className="h-4 w-[135px]" />
    </Skeleton.Row>
  )
})

export const ItemCardCtaContainerActiveStyles = css`
  bottom: 0;
  visibility: visible;
  transition: bottom ${ITEM_CARD_CTA_TRANSITION_DURATION}ms ease-in-out;
`

export const ItemCardCtaContainer = styled(VerticalAligned)<{
  active: boolean
}>`
  left: 0;
  width: 100%;
  position: absolute;
  bottom: -50px;
  visibility: hidden;
  transition:
    bottom ${ITEM_CARD_CTA_TRANSITION_DURATION}ms ease-in-out,
    visibility 0ms ${ITEM_CARD_CTA_TRANSITION_DURATION}ms;

  ${props =>
    props.active &&
    css`
      & {
        ${ItemCardCtaContainerActiveStyles}
      }
    `}
`

const ItemCardEditListingButtonSharedCss = css`
  font-size: 14px;
  min-width: unset;
`

const ItemCardEditListingPrimaryButton = styled(EditListingButton)`
  ${ItemCardEditListingButtonSharedCss}
  ${ItemCardPrimaryCtaButtonCss}
`

const ItemCardEditListingFullWidthButton = styled(EditListingButton)`
  ${ItemCardEditListingButtonSharedCss}
  ${ItemCardFullWidthCtaButtonCss}
`

export const ItemCardCta = Object.assign(ItemCardCtaBase, {
  Skeleton: ItemCardCtaSkeleton,
})
