import { graphql } from "relay-runtime"
import { useSelectionState } from "@/hooks/useSelectionState"
import { useSelectionStorage } from "@/hooks/useSelectionStorage"
import { useAssetSelectionStorage_item$data } from "@/lib/graphql/__generated__/useAssetSelectionStorage_item.graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"

const LOCAL_STORAGE_KEY = "assetSelectionKey"

export type StoredItem = Omit<
  useAssetSelectionStorage_item$data,
  " $fragmentType"
>

export const useAssetSelectionStorage = () => {
  return useSelectionStorage<StoredItem>(LOCAL_STORAGE_KEY)
}

// Provides a selection state that is not persisted in local storage.
export const useAssetSelectionStateStorage = (
  initialSelectedItems?: StoredItem[],
) => {
  return useSelectionState<StoredItem>({
    selectedItems: initialSelectedItems ?? [],
  })
}

export const readAssetSelectionItem = inlineFragmentize<
  useAssetSelectionStorage_item$data,
  StoredItem
>(
  graphql`
    fragment useAssetSelectionStorage_item on ItemType
    @argumentDefinitions(
      identity: { type: "IdentityInputType!" }
      withBestAsk: { type: "Boolean", defaultValue: false }
      withBestBid: { type: "Boolean", defaultValue: false }
    )
    @inline {
      __typename
      relayId
      chain {
        identifier
        isTradingEnabled
      }

      ... on AssetType {
        isErc721cFeeEnforced
        bestAllTypeBid @include(if: $withBestBid) {
          relayId
        }
        orderData @include(if: $withBestAsk) {
          bestAskV2 {
            relayId
            maker {
              address
            }
          }
          bestAskForOwner: bestAskV2(byAddress: $identity) {
            relayId
            maker {
              address
            }
          }
        }
        # eslint-disable-next-line relay/must-colocate-fragment-spreads
        ...asset_url
        # TODO: properly colocate this with TransferButton (where it's used)
        # eslint-disable-next-line relay/unused-fields
        isCompromised
      }
      ... on AssetBundleType {
        orderData {
          bestAskV2 @include(if: $withBestAsk) {
            relayId
            maker {
              address
            }
          }
          bestBidV2 @include(if: $withBestBid) {
            relayId
          }
        }
      }

      # eslint-disable-next-line relay/must-colocate-fragment-spreads
      ...item_sellUrl
      # eslint-disable-next-line relay/must-colocate-fragment-spreads
      ...AssetContextMenu_data
      # eslint-disable-next-line relay/must-colocate-fragment-spreads
      ...CancelItemOrdersButton_items
    }
  `,
  item => ({
    ...item,
    __typename: item.__typename,
    relayId: item.relayId,
    chain: {
      identifier: item.chain.identifier,
      isTradingEnabled: item.chain.isTradingEnabled,
    },
    isErc721cFeeEnforced: item.isErc721cFeeEnforced,
    bestAllTypeBid: item.bestAllTypeBid
      ? {
          relayId: item.bestAllTypeBid.relayId,
        }
      : null,
    orderData: {
      bestAskV2: item.orderData?.bestAskV2
        ? {
            relayId: item.orderData.bestAskV2.relayId,
            maker: {
              address: item.orderData.bestAskV2.maker.address,
            },
          }
        : null,
      bestAskForOwner:
        item.__typename === "AssetType"
          ? item.orderData?.bestAskForOwner
            ? {
                relayId: item.orderData.bestAskForOwner.relayId,
                maker: {
                  address: item.orderData.bestAskForOwner.maker.address,
                },
              }
            : null
          : item.orderData?.bestAskV2 // bestAskForOwner only works for assets.
          ? {
              relayId: item.orderData.bestAskV2.relayId,
              maker: {
                address: item.orderData.bestAskV2.maker.address,
              },
            }
          : null,
      bestBidV2: item.orderData?.bestBidV2 ?? null,
    },
  }),
)
