import React, { CSSProperties, useState } from "react"
import { breakpoints, classNames, Flex, FlexProps, Text } from "@opensea/ui-kit"
import { transparentize } from "polished"
import styled, { css } from "styled-components"
import { Z_INDEX } from "@/constants/zIndex"
import { BaseSlide, Carousel } from "@/design-system/Carousel"
import { useTranslate } from "@/hooks/useTranslate"
import { selectClassNames } from "@/lib/helpers/styling"
import { media } from "@/styles/styleUtils"

type Props = {
  slideItems: BaseSlide[]
  totalSlides?: number
  badgePadding?: CSSProperties["padding"]
  index?: number
}
// 500 is the ideal max card size
const MAX_CARD_WIDTH = 500

export const ItemCardCarousel = ({
  index,
  slideItems,
  totalSlides,
  badgePadding = "8px",
}: Props) => {
  const [carouselIndex, setCarouselIndex] = useState<number>(1)
  const t = useTranslate("components")

  return (
    <HomeContainer>
      <CarouselCount
        className={classNames("h-6 px-2 py-0.5 lg:h-8 lg:px-2 lg:py-1")}
        style={{ left: badgePadding, top: badgePadding }}
      >
        <Text.Body className="text-white" size="tiny" weight="semibold">
          {t(
            "assets.card.bundle.count",
            "{{carouselIndex}} of {{totalSlides}}",
            {
              carouselIndex,
              totalSlides,
            },
          )}
        </Text.Body>
      </CarouselCount>

      <Carousel
        centeredSlides
        centeredSlidesBounds
        className={selectClassNames(
          "ItemCarousel",
          {
            "one-card": totalSlides === 1,
            "two-cards": totalSlides === 2,
            "three-cards": totalSlides === 3,
          },
          "ItemCarousel--carousel",
        )}
        dotPaginationBelowSlides
        enableArrowControls
        enableDotPagination
        enableFreeScroll
        enableMousewheel
        handleActiveIndexChange={index => {
          setCarouselIndex(index + 1)
        }}
        id={`item-card-carousel-${index}`}
        overrides={{
          containerStyles: css`
            width: 100%;
            height: 100%;
          `,
        }}
        renderSlide={({ collection }) => collection}
        showScrollbar={false}
        slides={slideItems}
        slidesPerView={1}
        variant="item-card"
      />
    </HomeContainer>
  )
}

const CarouselCount = styled(Flex)`
  background-color: ${props =>
    transparentize(0.6, props.theme.colors.silverChalice)};
  backdrop-filter: blur(10px);
  align-items: center;
  border-radius: 8px;
  position: absolute;
  z-index: ${Z_INDEX.ITEM_CARD_SELECT_ICON};
`

const HomeContainer = styled(Flex)<FlexProps>`
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: calc(170px + (3 * ${MAX_CARD_WIDTH}px));

  & .ItemCarousel--one-card {
    max-width: ${MAX_CARD_WIDTH}px;
  }

  & .ItemCarousel--two-cards {
    max-width: calc(2 * ${MAX_CARD_WIDTH}px);
  }

  ${media({
    sm: css`
      & .ItemCarousel--two-cards {
        max-width: calc((2 * ${MAX_CARD_WIDTH}px));
      }
      & .ItemCarousel--three-cards {
        max-width: calc(170px + (2 * ${MAX_CARD_WIDTH}px));
      }
    `,
    lg: css`
      & .ItemCarousel--two-cards {
        max-width: calc((2 * ${MAX_CARD_WIDTH}px));
      }
      & .ItemCarousel--three-cards {
        max-width: calc(170px + (3 * ${MAX_CARD_WIDTH}px));
      }
    `,
    xl: css`
      max-width: calc(170px + (4 * ${MAX_CARD_WIDTH}px));
      & .ItemCarousel--two-cards {
        max-width: calc((2 * ${MAX_CARD_WIDTH}px));
      }
      & .ItemCarousel--three-cards {
        max-width: calc(4 * ${MAX_CARD_WIDTH}px);
      }
    `,
  })}

  .ItemCarousel--carousel {
    overflow: hidden;
    padding-right: 10px;
    /* Using medium breakpoint + 1 because layout is broken at breakpoint 815px due to exclusive breakpoint settings from Carousel component */
    @media (min-width: ${breakpoints.md + 1}px) {
      /* This allows the left/right arrows to not get cut off */
      overflow: initial;
      padding: 0;
    }
    ${media({
      md: css`
        margin-right: 0px;
      `,
    })}
  }
`
