import { useCallback, useEffect, useMemo } from "react"
import { IS_TESTNET } from "@/constants/testnet"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { useLocalForageState } from "@/hooks/useLocalForage"

const MAINNET_LOCAL_STORAGE_KEY_PREFIX = "selection_"
const TESTNET_LOCAL_STORAGE_KEY_PREFIX = "testnet_selection_"

const LOCAL_STORAGE_KEY_PREFIX = IS_TESTNET
  ? TESTNET_LOCAL_STORAGE_KEY_PREFIX
  : MAINNET_LOCAL_STORAGE_KEY_PREFIX

type StoredItem = {
  relayId: string
}

type State<T> = {
  walletAddress: string | undefined
  selectedItems: readonly T[]
  selectedIds: readonly string[]
}

/**
 * Manages selection state for a list of items, while storing the state using
 * localforage. Handles restoring state from localforage.
 *
 * Prefer useSelectionState if you don't need to restore across route changes or
 * browser refreshes.
 */
export const useSelectionStorage = <T extends StoredItem>(
  localStorageKey: string,
) => {
  const walletAddress = useConnectedAddress()
  const initialState = useMemo(
    () => ({
      walletAddress,
      selectedIds: [],
      selectedItems: [],
    }),
    [walletAddress],
  )
  const storageKey = LOCAL_STORAGE_KEY_PREFIX + localStorageKey
  const [state, setState] = useLocalForageState<State<T>>(
    storageKey,
    initialState,
  )

  useEffect(() => {
    if (walletAddress && state.walletAddress !== walletAddress) {
      setState(initialState)
    }
  }, [walletAddress, setState, state.walletAddress, initialState])

  const select = useCallback(
    (item: T) => {
      setState(prev => {
        return {
          ...prev,
          selectedIds: [...prev.selectedIds, item.relayId],
          selectedItems: [...prev.selectedItems, item],
        }
      })
    },
    [setState],
  )

  const batchSelect = useCallback(
    (items: T[]) => {
      setState(prev => {
        return {
          ...prev,
          selectedIds: items.map(({ relayId }) => relayId),
          selectedItems: items,
        }
      })
    },
    [setState],
  )

  const unselect = useCallback(
    (itemRelayIds: string[]) => {
      setState(prev => {
        return {
          ...prev,
          selectedIds: prev.selectedIds.filter(
            relayId => !itemRelayIds.includes(relayId),
          ),
          selectedItems: prev.selectedItems.filter(
            ({ relayId }) => !itemRelayIds.includes(relayId),
          ),
        }
      })
    },
    [setState],
  )

  const unselectAll = useCallback(() => {
    setState(prev => ({
      ...prev,
      selectedIds: [],
      selectedItems: [],
    }))
  }, [setState])

  const clear = useCallback(() => {
    setState(initialState)
  }, [initialState, setState])

  return {
    selectedIds: state.selectedIds,
    selectedItems: state.selectedItems,
    select,
    batchSelect,
    unselect,
    unselectAll,
    clear,
  }
}
