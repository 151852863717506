/**
 * @generated SignedSource<<4ea8a3fe9cc8dc2b8520565243460b68>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderV2OrderType = "BASIC" | "CRITERIA" | "DUTCH" | "ENGLISH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ItemCardFooter$data = {
  readonly __typename: string;
  readonly bundleCollection?: {
    readonly isVerified: boolean;
    readonly name: string;
    readonly slug: string;
    readonly " $fragmentSpreads": FragmentRefs<"collection_url" | "useIsRarityEnabled_collection">;
  } | null;
  readonly collection?: {
    readonly isVerified: boolean;
    readonly name: string;
    readonly slug: string;
    readonly " $fragmentSpreads": FragmentRefs<"collection_url" | "useIsRarityEnabled_collection">;
  };
  readonly defaultRarityData?: {
    readonly " $fragmentSpreads": FragmentRefs<"RarityIndicator_data">;
  } | null;
  readonly isDelisted?: boolean;
  readonly largestOwner?: {
    readonly owner: {
      readonly " $fragmentSpreads": FragmentRefs<"AccountLink_data">;
    };
  } | null;
  readonly name: string | null;
  readonly orderData: {
    readonly bestAskForOwnerItemCard?: {
      readonly " $fragmentSpreads": FragmentRefs<"ItemCardFooter_bestAskV2">;
    } | null;
    readonly bestAskV2: {
      readonly " $fragmentSpreads": FragmentRefs<"ItemCardFooter_bestAskV2">;
    } | null;
    readonly bestBidV2: {
      readonly orderType: OrderV2OrderType;
      readonly priceType: {
        readonly unit: string;
      };
      readonly " $fragmentSpreads": FragmentRefs<"ItemCardPrice_data">;
    } | null;
  };
  readonly pendingTxn: {
    readonly blockExplorerLink: string | null;
    readonly maxFeeGwei: string | null;
    readonly priorityFeeGwei: string | null;
    readonly seenAt: string | null;
    readonly status: string | null;
    readonly transactionHash: string | null;
  } | null;
  readonly relayId: string;
  readonly saleOrListingCancellationOccurred: boolean | null;
  readonly tokenId?: string;
  readonly " $fragmentSpreads": FragmentRefs<"AssetSearchListViewTableAssetInfo_item" | "ItemCardContent" | "ItemMetadata" | "item_url" | "useItemCardCta_item">;
  readonly " $fragmentType": "ItemCardFooter";
};
export type ItemCardFooter$key = {
  readonly " $data"?: ItemCardFooter$data;
  readonly " $fragmentSpreads": FragmentRefs<"ItemCardFooter">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "ItemCardFooter_bestAskV2"
  }
],
v3 = {
  "kind": "Variable",
  "name": "identity",
  "variableName": "identity"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tokenId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = [
  (v5/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isVerified",
    "storageKey": null
  },
  {
    "kind": "InlineDataFragmentSpread",
    "name": "collection_url",
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isCategory",
        "storageKey": null
      }
    ],
    "args": null,
    "argumentDefinitions": ([]/*: any*/)
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "useIsRarityEnabled_collection"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "identity"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "shouldShowBestBid"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showBestAskForOwner"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showContextMenu"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemCardFooter",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ESOrderDataType",
      "kind": "LinkedField",
      "name": "orderData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderV2Type",
          "kind": "LinkedField",
          "name": "bestBidV2",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "orderType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceType",
              "kind": "LinkedField",
              "name": "priceType",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unit",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ItemCardPrice_data"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderV2Type",
          "kind": "LinkedField",
          "name": "bestAskV2",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "condition": "showBestAskForOwner",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "bestAskForOwnerItemCard",
              "args": [
                {
                  "kind": "Variable",
                  "name": "byAddress",
                  "variableName": "identity"
                }
              ],
              "concreteType": "OrderV2Type",
              "kind": "LinkedField",
              "name": "bestAskV2",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        (v3/*: any*/),
        {
          "kind": "Variable",
          "name": "shouldShowBestBid",
          "variableName": "shouldShowBestBid"
        },
        {
          "kind": "Variable",
          "name": "showBestAskForOwner",
          "variableName": "showBestAskForOwner"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ItemMetadata"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isDelisted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RarityDataType",
          "kind": "LinkedField",
          "name": "defaultRarityData",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RarityIndicator_data"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionType",
          "kind": "LinkedField",
          "name": "collection",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetOwnershipType",
          "kind": "LinkedField",
          "name": "largestOwner",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AccountType",
              "kind": "LinkedField",
              "name": "owner",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AccountLink_data"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AssetSearchListViewTableAssetInfo_item"
        }
      ],
      "type": "AssetType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "bundleCollection",
          "args": null,
          "concreteType": "CollectionType",
          "kind": "LinkedField",
          "name": "collection",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": null
        }
      ],
      "type": "AssetBundleType",
      "abstractKey": null
    },
    {
      "args": [
        (v3/*: any*/),
        {
          "kind": "Variable",
          "name": "showContextMenu",
          "variableName": "showContextMenu"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useItemCardCta_item"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "item_url",
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "asset_url",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetContractType",
                  "kind": "LinkedField",
                  "name": "assetContract",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "address",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v4/*: any*/),
                (v7/*: any*/)
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "type": "AssetType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "bundle_url",
              "selections": [
                (v5/*: any*/),
                (v7/*: any*/)
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "type": "AssetBundleType",
          "abstractKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ItemCardContent"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "saleOrListingCancellationOccurred",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "pendingTxn",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "maxFeeGwei",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "priorityFeeGwei",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "seenAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "transactionHash",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "blockExplorerLink",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};
})();

(node as any).hash = "e3f0c043e79997ccade044d772914188";

export default node;
