import React from "react"
import { ToggleButtonGroup } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { useAccountOrCollectionPageContext } from "@/components/layout/AccountOrCollectionPage/hooks/useAccountOrCollectionPageContext"
import { AssetCardVariant } from "@/components/search/assets/AssetSearchView"
import { Block } from "@/design-system/Block"
import { trackClickItemCardVariantToggle } from "@/lib/analytics/events/itemEvents"
import { media } from "@/styles/styleUtils"

const CARD_VARIANT_MIN_WIDTH = {
  desktop: {
    cozy: 260,
    compact: 160,
    "list-view": 90,
  },
  /* NOTE(@laurafiuza): We don't respect asset card variant choice
     on mobile screen widths. */
  mobile: {
    cozy: 140,
    compact: 140,
    "list-view": 90,
  },
}

type Device = keyof typeof CARD_VARIANT_MIN_WIDTH

export const getCardVariantMinWidth = (
  device: Device,
  cardVariant: AssetCardVariant,
): number => {
  if (cardVariant === "natural") {
    return CARD_VARIANT_MIN_WIDTH["desktop"]["cozy"]
  }

  return CARD_VARIANT_MIN_WIDTH[device][cardVariant]
}

type Props = {
  currentCardVariant?: AssetCardVariant
  setCurrentCardVariant: React.Dispatch<
    React.SetStateAction<AssetCardVariant | undefined>
  >
}

export const AssetCardVariantToggle = ({
  setCurrentCardVariant,
  currentCardVariant,
}: Props) => {
  const { isListViewEnabled, setSelectedCardVariant } =
    useAccountOrCollectionPageContext()

  const handleChange = (value: AssetCardVariant) => {
    setCurrentCardVariant(value)
    setSelectedCardVariant(value)
    trackClickItemCardVariantToggle({ variant: value })
  }

  return (
    <ToggleButtonGroupWrapper>
      <ToggleButtonGroup
        defaultValue="cozy"
        value={currentCardVariant}
        onValueChange={handleChange}
      >
        {isListViewEnabled && (
          <ToggleButtonGroup.Button
            data-testid="list-view-toggle"
            icon="format_list_bulleted"
            value="list-view"
          />
        )}
        <ToggleButtonGroup.Button
          data-testid="compact-toggle"
          icon="grid_on"
          value="compact"
        />
        <ToggleButtonGroup.Button
          data-testid="cozy-toggle"
          icon="window"
          value="cozy"
        />
        <ToggleButtonGroup.Button
          data-testid="natural-toggle"
          icon="auto_awesome_mosaic"
          value="natural"
        />
      </ToggleButtonGroup>
    </ToggleButtonGroupWrapper>
  )
}

const ToggleButtonGroupWrapper = styled(Block)`
  display: none;
  ${media({
    md: css`
      display: block;
    `,
  })}
`
