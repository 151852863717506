import { useCallback, useRef } from "react"
import { useContextSelector } from "use-context-selector"
import { AssetSelectionContext } from "@/features/account/components/AccountPage/components/AssetSelectionContextProvider.react"
import { StoredItem } from "@/features/assets/hooks/useAssetSelectionStorage"
import { ChainIdentifier } from "@/hooks/useChains/types"

export const useAssetContextSelectedIds = () =>
  useContextSelector(AssetSelectionContext, context => context.selectedIds)

export const useGetAssetContextSelectedIdsCount = () => {
  const selectedIds = useAssetContextSelectedIds()
  const selectedIdsRef = useRef(selectedIds.length)
  selectedIdsRef.current = selectedIds.length

  return useCallback(() => selectedIdsRef.current, [])
}

export const useAssetSelectionState = (totalItemCount: number) => {
  const selectedIds = useAssetContextSelectedIds()

  if (selectedIds.length === 0) {
    return "none"
  } else if (selectedIds.length === totalItemCount) {
    return "all"
  }

  return "some"
}

export const useHasOneOrMoreAssetsSelected = () => {
  return useContextSelector(AssetSelectionContext, context =>
    Boolean(context.selectedIds.length),
  )
}

export const useIsAssetSelected = (relayId?: string | null) =>
  useContextSelector(AssetSelectionContext, context =>
    context.selectedIds.includes(relayId ?? ""),
  )

export const useAssetContextSelectedItems = () =>
  useContextSelector(AssetSelectionContext, context => context.selectedItems)

export const useAssetContextActions = () => {
  const onClose = useContextSelector(
    AssetSelectionContext,
    context => context.onClose,
  )

  const onDelete = useContextSelector(
    AssetSelectionContext,
    context => context.onDelete,
  )

  const onDeleteAll = useContextSelector(
    AssetSelectionContext,
    context => context.onDeleteAll,
  )

  const onSelect = useContextSelector(
    AssetSelectionContext,
    context => context.onSelect,
  )

  const onBatchSelect = useContextSelector(
    AssetSelectionContext,
    context => context.onBatchSelect,
  )

  return {
    onClose,
    onDelete,
    onDeleteAll,
    onSelect,
    onBatchSelect,
  }
}

export const useToggleAssetSelection = (asset?: StoredItem | null) => {
  const isSelected = useIsAssetSelected(asset?.relayId)

  const { onSelect, onDelete } = useAssetContextActions()

  const toggle = useCallback(() => {
    if (asset) {
      if (isSelected) {
        onDelete([asset.relayId])
      } else {
        onSelect(asset)
      }
    }
  }, [asset, isSelected, onSelect, onDelete])

  return toggle
}

export const useAssetSelectionChain = (): ChainIdentifier | undefined => {
  return useContextSelector(AssetSelectionContext, context => {
    if (context.selectedItems.length === 0) {
      return undefined
    }

    const firstChain = context.selectedItems[0].chain
    if (
      context.selectedItems.every(
        item => item.chain.identifier === firstChain.identifier,
      )
    ) {
      return firstChain.identifier
    }

    return undefined
  })
}
