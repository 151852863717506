import React, { useRef, useState } from "react"
import { useUpdate, useUpdateEffect } from "react-use"
import { useDebouncedCallback } from "use-debounce"
import { useIsOpen } from "@/hooks/useIsOpen"
import { Promiseable } from "../../lib/helpers/promise"
import {
  Select,
  LoadingConfiguration,
  SelectOption,
  SelectProps,
} from "../Select"

const DEBOUNCE_DELAY = 300

type Cache<Option extends SelectOption> = Record<
  string,
  ReadonlyArray<Option> | undefined
>

export type AsyncSelectProps<
  Value extends string = string,
  Option extends SelectOption<Value> = SelectOption<Value>,
> = {
  loadingConfiguration?: LoadingConfiguration
  useCache?: boolean
  search: (query: string) => Promiseable<ReadonlyArray<Option>>
} & Pick<
  SelectProps<Value, Option>,
  | "renderItem"
  | "value"
  | "onSelect"
  | "placeholder"
  | "endEnhancer"
  | "startEnhancer"
  | "clearable"
  | "id"
  | "name"
  | "maxHeight"
  | "searchFilter"
>

const defaultSearchFilter = () => true

export const AsyncSelect = <
  Value extends string,
  Option extends SelectOption<Value>,
>({
  loadingConfiguration,
  value,
  useCache = true,
  search,
  searchFilter = defaultSearchFilter,
  ...rest
}: AsyncSelectProps<Value, Option>) => {
  const { isOpen, setIsOpen } = useIsOpen()
  const [isLoading, setIsLoading] = useState<SelectProps["isLoading"]>(false)
  const cacheRef = useRef<Cache<Option>>({})
  const queryRef = useRef("")

  const forceUpdate = useUpdate()

  const handleInputChange = useDebouncedCallback(async (query: string) => {
    queryRef.current = query

    // Use cached results, if applicable.
    if (useCache && cacheRef.current[query]) {
      // Re-render the component with the cached results.
      forceUpdate()
      return
    }

    setIsLoading(loadingConfiguration ?? true)

    try {
      const results = await search(query)
      cacheRef.current[query] = results
    } finally {
      setIsLoading(false)
    }
  }, DEBOUNCE_DELAY)

  // Clear the search every time we execute a "new" search
  useUpdateEffect(() => {
    queryRef.current = ""
    handleInputChange("")
  }, [value, handleInputChange, isOpen])

  const cachedQuery = cacheRef.current[queryRef.current] ?? []

  return (
    <Select
      isLoading={isLoading}
      options={cachedQuery}
      searchFilter={searchFilter}
      value={value}
      onChange={handleInputChange}
      onOpenChange={setIsOpen}
      {...rest}
    />
  )
}
