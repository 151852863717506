/**
 * @generated SignedSource<<fe30a54e4c08e19e6d63f995ca4217fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CollectionVerificationRequestStatus = "APPROVED" | "DENIED" | "PENDING" | "%future added value";
export type CollectionVerificationFormRequestMutation$variables = {
  collection: string;
  originalWorkAccepted: boolean;
};
export type CollectionVerificationFormRequestMutation$data = {
  readonly collections: {
    readonly requestVerification: {
      readonly status: CollectionVerificationRequestStatus;
    };
  };
};
export type CollectionVerificationFormRequestMutation = {
  response: CollectionVerificationFormRequestMutation$data;
  variables: CollectionVerificationFormRequestMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "collection"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "originalWorkAccepted"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "collection",
    "variableName": "collection"
  },
  {
    "kind": "Variable",
    "name": "originalWorkAccepted",
    "variableName": "originalWorkAccepted"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CollectionVerificationFormRequestMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CollectionMutationType",
        "kind": "LinkedField",
        "name": "collections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CollectionVerificationRequestType",
            "kind": "LinkedField",
            "name": "requestVerification",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CollectionVerificationFormRequestMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CollectionMutationType",
        "kind": "LinkedField",
        "name": "collections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CollectionVerificationRequestType",
            "kind": "LinkedField",
            "name": "requestVerification",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "86e1ef4e4c9016a3e34f5102945b5b74",
    "id": null,
    "metadata": {},
    "name": "CollectionVerificationFormRequestMutation",
    "operationKind": "mutation",
    "text": "mutation CollectionVerificationFormRequestMutation(\n  $collection: CollectionRelayID!\n  $originalWorkAccepted: Boolean!\n) {\n  collections {\n    requestVerification(collection: $collection, originalWorkAccepted: $originalWorkAccepted) {\n      status\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "92bd5797dae51483843b4ce456cf5347";

export default node;
