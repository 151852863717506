import { borderRadius } from "@/styles/themes"

export const ITEM_CARD_ADD_TO_CART_AND_QUICK_BUY_BUTTON_OVERRIDES = {
  ItemAddToCartButton: {
    props: {
      overrides: {
        Button: {
          props: {
            style: {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: borderRadius.default,
              height: "36px",
              padding: 0,
            },
          },
        },
        Text: {
          props: {
            size: "small",
          },
        },
      },
    },
  },
  QuickBuyButton: {
    props: {
      overrides: {
        Button: {
          props: {
            style: {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: borderRadius.default,
              borderBottomRightRadius: 0,
              height: "36px",
              padding: 0,
            },
          },
        },
        Text: {
          props: {
            size: "small",
          },
        },
      },
    },
  },
} as const

export const ITEM_CARD_CTA_TRANSITION_DURATION = 75 // in milliseconds
