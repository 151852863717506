import React, { useCallback, useRef } from "react"
import {
  Icon,
  useIsLessThanLg,
  useIsLessThanSm,
  UnstyledButton,
  Flex,
} from "@opensea/ui-kit"
import localforage from "localforage"
import { noop } from "lodash"
import { transparentize, rgba } from "polished"
import {
  commitLocalUpdate,
  useFragment,
  useRelayEnvironment,
} from "react-relay"
import { useUpdateEffect } from "react-use"
import styled, { css } from "styled-components"
import {
  ItemCardCta,
  ItemCardCtaContainer,
  ItemCardCtaContainerActiveStyles,
} from "@/components/assets/ItemCardCta/ItemCardCta.react"
import { Badge } from "@/components/common/Badge.react"
import { ChainLogoWithTooltip } from "@/components/common/ChainLogo"
import { Link } from "@/components/common/Link"
import { FILTER_BAR_HEIGHT } from "@/components/layout/AccountOrCollectionPage/constants"
import { DelistedNoticeModal } from "@/components/modals/DelistedNoticeModal.react"
import { AssetCardVariant } from "@/components/search/assets/AssetSearchView"
import { Z_INDEX } from "@/constants/zIndex"
import { Block } from "@/design-system/Block"
import { Modal } from "@/design-system/Modal"
import { Tooltip } from "@/design-system/Tooltip"
import {
  useHasOneOrMoreAssetsSelected,
  useIsAssetSelected,
} from "@/features/account/hooks/useAssetSelection"
import { trackClickAssetSelection } from "@/features/assets/components/AssetSelection/analytics"
import { COLLECTION_FOOTER_HEIGHT } from "@/features/collections/components/CollectionPage/components/CollectionFooter"
import {
  GRID_VIEW_ITEM_REMOVAL_DELAY,
  removeItemFromStore,
} from "@/features/live-updates/utils"
import { SWEEP_BOTTOM_SHEET_HEIGHT } from "@/features/sweep/constants"
import {
  useSweepFormIsSweepModeToggled,
  useSweepHoveredItemRelayId,
  useSweepUpToItem,
} from "@/features/sweep/SweepContextProvider.react"
import { usePageVisibility } from "@/hooks/usePageVisibility"
import { useScrollIntoViewIfNeeded } from "@/hooks/useScrollIntoViewIfNeeded"
import { useTranslate } from "@/hooks/useTranslate"
import { CollectionTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/CollectionTrackingContext.react"
import { ItemTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/ItemTrackingContext.react"
import { SourceTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/SourceTrackingContext.react"
import {
  ItemCard_data$data,
  ItemCard_data$key,
} from "@/lib/graphql/__generated__/ItemCard_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { getItemUrl } from "@/lib/helpers/item"
import {
  bn,
  MIN_DISPLAY_FORMAT,
  shortSymbolDisplay,
} from "@/lib/helpers/numberUtils"
import { selectClassNames } from "@/lib/helpers/styling"
import { media, themeVariant } from "@/styles/styleUtils"
import { NAV_HEIGHT_PX } from "@/styles/variables"
import { ImageContainer } from "./components/ItemImage/ItemImage.react"
import { ItemCardContent } from "./ItemCardContent.react"
import {
  ItemCardFooter,
  ItemCardMetadataContainer,
} from "./ItemCardFooter.react"
import { LAST_CLICKED_BEST_ASK_DATA_KEY } from "./localstorage"

export type ItemCardOnClickExtra = {
  event: React.MouseEvent<HTMLAnchorElement>
}

export type ItemCardProps = {
  className?: string
  data: ItemCard_data$key | null
  disabledReason?: string
  index?: number
  isDisabled?: boolean
  isHighlighted?: boolean
  isPreviewHighlighted?: boolean
  showContextMenu?: boolean
  width: number
  containerWidth?: number
  showCollectionName?: boolean
  onClick?: (
    item: ItemCard_data$data | null,
    extra: ItemCardOnClickExtra,
  ) => void
  /** Account address of the profile where this item card is being shown. */
  accountAddress?: string
  fillContainerWidth?: boolean
  variant?: AssetCardVariant
  shouldScrollIntoViewWhenHighlightToggled?: boolean
  shouldOpenNewTab?: boolean
  showQuantityBadge?: boolean
  showAssetMediaEditions?: boolean
  selectOnlyMode?: boolean
  toggleAssetSelected?: () => void
  sizes?: string
}

export const ItemCardFragment = graphql`
  fragment ItemCard_data on ItemType
  @argumentDefinitions(
    identity: { type: "IdentityInputType", defaultValue: {} }
    showContextMenu: { type: "Boolean", defaultValue: false }
    showQuantity: { type: "Boolean", defaultValue: false }
    showBestAskForOwner: { type: "Boolean", defaultValue: false }
    shouldShowBestBid: { type: "Boolean", defaultValue: false }
  ) {
    __typename
    relayId
    chain {
      identifier
    }
    saleOrListingCancellationOccurred
    orderData {
      bestAskV2 {
        perUnitPriceType {
          usd
        }
      }
    }
    ... on AssetType {
      isDelisted
      totalQuantity
      collection {
        slug
        ...CollectionTrackingContext_collection
      }
      ...itemEvents_data
    }
    ... on AssetBundleType {
      bundleCollection: collection {
        slug
        ...CollectionTrackingContext_collection
      }
    }
    ...ItemCardContent
      @arguments(identity: $identity, showQuantity: $showQuantity)
    ...ItemCardFooter
      @arguments(
        identity: $identity
        shouldShowBestBid: $shouldShowBestBid
        showBestAskForOwner: $showBestAskForOwner
        showContextMenu: $showContextMenu
      )
    ...ItemCardCta_item
      @arguments(identity: $identity, showContextMenu: $showContextMenu)
    ...item_url
    ...ItemTrackingContext_item
  }
`

export const ItemCard = React.memo(function ItemCard({
  data: dataKey,
  width,
  showContextMenu,
  className,
  disabledReason,
  index,
  isDisabled,
  isHighlighted,
  isPreviewHighlighted,
  containerWidth,
  showCollectionName,
  onClick,
  accountAddress,
  fillContainerWidth,
  variant,
  shouldOpenNewTab,
  shouldScrollIntoViewWhenHighlightToggled = false,
  showQuantityBadge,
  showAssetMediaEditions,
  selectOnlyMode = false,
  sizes,
  toggleAssetSelected = noop,
}: ItemCardProps) {
  const t = useTranslate("collection")
  const sweepUpToItem = useSweepUpToItem()
  const isSweepModeToggled = useSweepFormIsSweepModeToggled()
  const { setHoveredItemRelayId } = useSweepHoveredItemRelayId()
  const hasAssetSelected = useHasOneOrMoreAssetsSelected()

  const data = useFragment(ItemCardFragment, dataKey)
  const isSelected = useIsAssetSelected(data?.relayId)

  const isSelectionActive = hasAssetSelected
  // selectOnlyMode ensures select button is visible before selecting the first item.
  const showSelectButton =
    isSelectionActive || showContextMenu || selectOnlyMode

  const isSmallScreen = useIsLessThanSm()
  const isMdScreen = useIsLessThanLg()
  const itemId = data?.relayId
  const environment = useRelayEnvironment()

  const isBundle = data?.__typename === "AssetBundleType"

  const contentBottomRef = useRef<HTMLDivElement>(null)

  const collection = data?.collection ?? data?.bundleCollection
  const animateSaleEvent = Boolean(
    !!data?.saleOrListingCancellationOccurred &&
      collection?.slug &&
      data.relayId,
  )

  const visible = usePageVisibility()
  const timeoutRef = useRef<NodeJS.Timeout | undefined>()
  if (animateSaleEvent && data && data.relayId) {
    if (!timeoutRef.current) {
      const removeHandler = () =>
        commitLocalUpdate(environment, store =>
          removeItemFromStore(data, store),
        )
      if (visible) {
        timeoutRef.current = setTimeout(
          removeHandler,
          GRID_VIEW_ITEM_REMOVAL_DELAY,
        )
      } else {
        removeHandler()
      }
    }
  }

  const [containerRef, scrollIntoView] = useScrollIntoViewIfNeeded({
    marginTop: NAV_HEIGHT_PX + FILTER_BAR_HEIGHT,
    marginBottom: isMdScreen
      ? SWEEP_BOTTOM_SHEET_HEIGHT
      : COLLECTION_FOOTER_HEIGHT,
    extraTopOffset: 16,
  })

  const onMouseOver = useCallback(() => {
    if (isSweepModeToggled) {
      setHoveredItemRelayId(itemId)
    }
  }, [isSweepModeToggled, itemId, setHoveredItemRelayId])

  const onMouseOut = useCallback(() => {
    if (isSweepModeToggled) {
      setHoveredItemRelayId(undefined)
    }
  }, [isSweepModeToggled, setHoveredItemRelayId])

  useUpdateEffect(() => {
    if (shouldScrollIntoViewWhenHighlightToggled) {
      scrollIntoView()
    }
  }, [shouldScrollIntoViewWhenHighlightToggled])

  const renderContent = () => {
    return (
      <ItemCardContent
        disabled={isDisabled}
        fillContainerWidth={fillContainerWidth}
        index={index}
        item={data}
        natural={variant === "natural"}
        showAssetMediaEditions={showAssetMediaEditions}
        sizes={sizes}
        width={width}
      />
    )
  }

  const renderFooter = () => {
    if (!data) {
      return <ItemCardFooter.Skeleton />
    }

    return (
      <ItemCardFooter
        accountAddress={accountAddress}
        disabled={isDisabled}
        item={data}
        showCollectionName={showCollectionName}
        variant={variant}
      />
    )
  }

  const totalItemQuantity = data?.totalQuantity ?? "1"
  const formattedTotalItemQuantity = shortSymbolDisplay(bn(totalItemQuantity), {
    shortenBillion: true,
    threshold: MIN_DISPLAY_FORMAT,
    noDecimalWholeNumber: true,
  })
  const showQuantity = bn(totalItemQuantity).gt(1) && showQuantityBadge

  const assetCard = (
    <>
      {showQuantity && (
        <ItemCardBadge
          data-item="data-item-badge"
          text={t("itemCardContent.quantity", "x{{quantity}}", {
            quantity: formattedTotalItemQuantity,
          })}
        />
      )}
      {renderContent()}
      <TooltipContainer ref={contentBottomRef} />
      {variant !== "natural" && renderFooter()}
    </>
  )

  const setLastClickedBestAskData = () => {
    const bestAskUsdPrice = data?.orderData.bestAskV2?.perUnitPriceType.usd
    if (itemId && bestAskUsdPrice) {
      localforage.setItem(
        LAST_CLICKED_BEST_ASK_DATA_KEY,
        `${itemId}-${bestAskUsdPrice}`,
      )
    }
  }

  const onSelect = useCallback(
    (target: "selectButton" | "itemCard") => {
      if (!data || data.__typename !== "AssetType") {
        return
      }
      trackClickAssetSelection(data, {
        selectAction: isSelected ? "unselect" : "select",
        target,
      })
    },
    [data, isSelected],
  )

  return (
    <SourceTrackingContextProvider source="ItemCard">
      <CollectionTrackingContextProvider collection={collection ?? null}>
        <ItemTrackingContextProvider item={data}>
          <Tooltip
            appendTo={contentBottomRef.current ?? undefined}
            content={disabledReason}
            contentPadding="12px 16px"
            delay={[150, 150]}
            disabled={!isDisabled || !disabledReason}
            placement="bottom"
            popperOptions={{
              modifiers: [
                {
                  name: "preventOverflow",
                  options: {
                    padding: {
                      bottom: 12,
                    },
                  },
                },
              ],
            }}
          >
            <AssetCardContainer
              $fadeOut={animateSaleEvent}
              className={selectClassNames("Asset", { loaded: !!data })}
            >
              <AssetCard
                $isHighlighted={isHighlighted}
                $isPreviewHighlighted={isPreviewHighlighted}
                $isSmallScreen={isSmallScreen}
                className={selectClassNames(
                  "Asset",
                  { isDisabled, isSelected, isSelectionActive, loaded: !!data },
                  className,
                )}
                data-relay-id={itemId}
                data-testid={itemId}
                ref={containerRef}
                style={{ width: containerWidth === 0 ? "100%" : width }}
                onMouseOut={onMouseOut}
                onMouseOver={onMouseOver}
              >
                {data?.isDelisted ? (
                  <Modal
                    closable={false}
                    trigger={open => (
                      <UnstyledButton
                        className={selectClassNames("Asset", {
                          anchor: true,
                          delisted: true,
                        })}
                        onClick={event => {
                          if (showSelectButton) {
                            event.preventDefault()
                            toggleAssetSelected()
                            onSelect("itemCard")
                          } else {
                            open()
                          }
                        }}
                      >
                        {assetCard}
                      </UnstyledButton>
                    )}
                  >
                    {onClose => (
                      <DelistedNoticeModal
                        variant="account"
                        onClose={onClose}
                      />
                    )}
                  </Modal>
                ) : (
                  <>
                    <Link
                      className={selectClassNames("Asset", {
                        anchor: true,
                        "placeholder-background": !data,
                      })}
                      href={!data ? undefined : getItemUrl(data)}
                      target={shouldOpenNewTab ? "_blank" : undefined}
                      onClick={event => {
                        if (showSelectButton && isSelectionActive && data) {
                          event.preventDefault()
                          toggleAssetSelected()
                          onSelect("itemCard")
                        } else if (isSweepModeToggled) {
                          event.preventDefault()
                          sweepUpToItem(itemId)
                        } else {
                          setLastClickedBestAskData()
                          onClick?.(data, { event })
                        }
                      }}
                    >
                      {assetCard}
                    </Link>
                    {/* Do not show item card CTAs when in select-only mode. */}
                    {variant !== "natural" && !selectOnlyMode && (
                      <Block className="absolute inset-x-0 bottom-0">
                        <ItemCardCta
                          item={data}
                          showContextMenu={
                            showContextMenu && !isSelectionActive
                          }
                          toggleAssetSelected={toggleAssetSelected}
                        />
                      </Block>
                    )}
                    {showSelectButton && (
                      <SelectItemCardButton
                        onClick={() => {
                          toggleAssetSelected()
                          onSelect("selectButton")
                        }}
                      >
                        {isSelected ? (
                          <ItemCardAddRemoveIcon size={20} value="remove" />
                        ) : (
                          <ItemCardAddRemoveIcon size={20} value="add" />
                        )}
                      </SelectItemCardButton>
                    )}
                    {data?.chain ? (
                      <ChainIconContainer $showBundlePill={isBundle}>
                        <StyledChainLogoWithTooltip
                          chain={data.chain.identifier}
                          color="white"
                          width={isMdScreen ? 18 : 24}
                        />
                      </ChainIconContainer>
                    ) : null}
                  </>
                )}
              </AssetCard>
            </AssetCardContainer>
          </Tooltip>
        </ItemTrackingContextProvider>
      </CollectionTrackingContextProvider>
    </SourceTrackingContextProvider>
  )
})

const ItemCardBadge = styled(Badge).attrs({ variant: "primary" })`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(12px, 12px);
  background-color: ${props =>
    transparentize(0.6, props.theme.colors.silverChalice)} !important;
  backdrop-filter: blur(10px);
  z-index: 1;
  border-radius: 8px !important;
`

const AssetCardContainer = styled(Block)<{ $fadeOut: boolean }>`
  ${ItemCardBadge} {
    transition: opacity 0.25s 0s ease-in-out;
  }

  &:hover {
    ${ItemCardBadge} {
      opacity: 0;
    }
  }

  animation: ${props => (props.$fadeOut ? "fadeOut 1.25s linear" : undefined)};

  @keyframes fadeOut {
    0% {
      opacity: 0.5;
    }
    80% {
      opacity: 0.5;
      transform: scale(1);
      animation-timing-function: ease-in-out;
    }
    100% {
      opacity: 0;
      transform: scale(0.5);
    }
  }
`

const SelectItemCardButton = styled(UnstyledButton)`
  background: transparent;
  cursor: pointer;
  padding: 8px; // Increase click/touch target.
  position: absolute;
  right: 0;
  top: 0;
  z-index: ${Z_INDEX.ITEM_CARD_SELECT_ICON};
`

const ChainIconContainer = styled(Flex)<{ $showBundlePill: boolean }>`
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: ${props =>
    transparentize(0.6, props.theme.colors.silverChalice)};
  backdrop-filter: blur(10px);
  height: 32px;
  width: 32px;
  border-radius: ${props => props.theme.borderRadius.circle};
  position: absolute;
  left: ${props => (props.$showBundlePill ? "60px" : "8px")};
  top: 8px;
  z-index: ${Z_INDEX.ITEM_CARD_SELECT_ICON};
  opacity: 0;
  cursor: pointer;
`

export const StyledChainLogoWithTooltip = styled(ChainLogoWithTooltip)`
  background: transparent;
  visibility: hidden;
`

const ItemCardAddRemoveIcon = styled(Icon)`
  background-color: ${props =>
    transparentize(0.6, props.theme.colors.silverChalice)};

  backdrop-filter: blur(10px);
  // Ensures consistent sizing with selected ItemCardAddRemoveIcon.
  border: 1px solid transparent;
  border-radius: ${props => props.theme.borderRadius.circle};
  color: ${props => props.theme.colors.white};
  padding: 4px;
`

const TooltipContainer = styled(Block)`
  & > * {
    margin-left: -8px;
  }
`

const AssetCard = styled.article<{
  $isSmallScreen?: boolean
  $isHighlighted?: boolean
  $isPreviewHighlighted?: boolean
}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: ${props => props.theme.borderRadius.default};
  position: relative;
  z-index: 2;
  overflow: hidden;
  background-color: ${props =>
    props.theme.colors.components.elevation.level1.regular.background};

  box-shadow: ${props =>
    props.theme.colors.components.elevation.level1.regular.shadow};

  ${props =>
    props.$isPreviewHighlighted &&
    css`
      outline: 3px solid ${rgba(props.theme.colors.primary, 0.4)};

      &:hover {
        border: 0;
      }
    `}

  ${props =>
    props.$isHighlighted &&
    css`
      outline: 3px solid ${props.theme.colors.primary};

      &:hover {
        border: 0;
      }
    `}

  ${props =>
    themeVariant({
      variants: {
        dark: {
          transition: "background-color 0.25s 0s ease-in-out",
        },
        light: {
          boxShadow: props.$isSmallScreen
            ? "0 4px 8px rgba(0, 0, 0, .04)"
            : "0 4px 15px rgba(0, 0, 0, .08)",
          transition: "box-shadow 0.25s 0s ease-in-out",
        },
      },
    })}

  @media (hover: hover) {
    &:hover {
      box-shadow: ${props =>
        props.theme.colors.components.elevation.level2.shadow};
      background: ${props =>
        props.theme.colors.components.elevation.level2.background};

      transition: 0.1s;

      ${ItemCardAddRemoveIcon} {
        opacity: 1;
        transition: opacity 0.4s ease-in-out;
      }

      ${ChainIconContainer} {
        opacity: 1;
        transition: opacity 0.4s ease-in-out;
      }

      ${StyledChainLogoWithTooltip} {
        visibility: visible;
        opacity: 1;
        transition:
          visibility 0s,
          opacity 0.4s ease-in-out;
      }

      ${ItemCardCtaContainer} {
        ${ItemCardCtaContainerActiveStyles}
      }

      ${ItemCardMetadataContainer} {
        opacity: 0;
        transition: opacity 0.075s ease-in-out;
      }

      ${ImageContainer} {
        transform: scale(1.12);
        transition-duration: 0.4s;
      }
    }
  }

  &:not(:hover) {
    ${ImageContainer} {
      transition: transform 0.4s;
    }

    ${ItemCardMetadataContainer} {
      opacity: 1;
      transition: opacity 0.075s ease-in-out 0.075s;
    }

    ${ItemCardAddRemoveIcon} {
      opacity: 0;
      transition: opacity 0.2s ease-in-out 0.075s;
    }

    ${ChainIconContainer} {
      opacity: 0;
      transition: opacity 0.2s ease-in-out 0.075s;
    }

    ${StyledChainLogoWithTooltip} {
      visibility: hidden;
      opacity: 0;
      transition:
        visibility 0.2s,
        opacity 0.2s ease-in-out 0.075s;
    }
  }

  &:active {
    transition: box-shadow 0.25s 0s ease-in-out;
    box-shadow: ${props =>
      props.theme.colors.components.elevation.level2.shadow};
  }

  &.Asset--isSelectionActive {
    opacity: 0.9;

    &:hover {
      opacity: 1;
    }
  }

  &.Asset--isSelected {
    opacity: 1;
  }

  &.Asset--isDisabled {
    pointer-events: none;

    .Asset--anchor {
      cursor: default;
    }
  }

  .Asset--anchor {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    border-radius: inherit;

    &.Asset--placeholder-background {
      background: ${props =>
        props.theme.colors.components.elevation.level1.regular.background};
    }

    .Asset--bundle-images {
      height: 64%;

      .Asset--pill {
        align-items: center;
        border-radius: 4px;
        display: flex;
        padding: 2px 4px;
        position: absolute;
        font-size: 11px;
        top: 6px;
        left: 6px;

        .Asset--bundle-icon {
          margin-right: 0.25em;
          font-size: 16px;
        }
      }
    }
  }

  .Asset--delisted {
    cursor: pointer;
    height: 100%;
    text-align: start;
    width: 100%;
  }

  .Asset--card-header-placeholder {
    /* TODO */
    height: 44px;
  }

  &.Asset--loaded {
    .AssetCardContent--actions {
      display: initial;
    }
  }

  .Asset--anchor {
    pointer-events: initial;
  }

  &.Asset--isDisabled {
    .Asset--anchor {
      pointer-events: none;
    }
  }

  .Asset--selected-icon {
    background-color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
    border-radius: ${props => props.theme.borderRadius.circle};
    color: white;
    font-size: 16px;
    font-weight: 600;
    padding: 4px;
    position: absolute;
    right: 3px;
    top: 3px;
    z-index: 1;
  }

  ${media({
    sm: css`
      .Asset--anchor {
        &.Asset--placeholder-background {
          border-radius: ${props => props.theme.borderRadius.default};
          overflow: hidden;
        }
      }

      .Asset--selected-icon {
        right: -12px;
        top: -12px;
      }
    `,
  })}
`
