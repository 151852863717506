import React from "react"
import { SpaceBetween, Text, FlexColumn, Flex } from "@opensea/ui-kit"
import { Link } from "@/components/common/Link"
import { LiveTimestamp } from "@/components/common/LiveTimestamp.react"
import { Overflow } from "@/components/common/Overflow"
import { useTranslate } from "@/hooks/useTranslate"
import { truncateAddress } from "@/lib/helpers/address"

type Props = {
  maxFeeGwei?: string | null
  priorityFeeGwei?: string | null
  seenAt?: Date | null
  transactionHash?: string | null
  blockExplorerLink?: string | null
}

export const ItemCardPendingTxnTooltip = ({
  maxFeeGwei,
  priorityFeeGwei,
  seenAt,
  transactionHash,
  blockExplorerLink,
}: Props) => {
  const t = useTranslate("components")
  if (
    !maxFeeGwei ||
    !priorityFeeGwei ||
    !seenAt ||
    !transactionHash ||
    !blockExplorerLink
  ) {
    return null
  }
  const formatFee = (fee: string | null) => {
    if (!fee) {
      return `? GWEI`
    }
    const rounded = Math.ceil(parseFloat(fee))
    return `${rounded} GWEI`
  }

  const truncatedTx = truncateAddress(transactionHash)

  return (
    <FlexColumn>
      <Flex className="mb-2 w-full justify-center border-b border-b-level-2 pb-2">
        <Text.Body className="text-primary" size="small" weight="semibold">
          {t("liveUpdatesInfo.pendingTransaction", "Pending transaction")}
        </Text.Body>
      </Flex>
      <SpaceBetween>
        <Text.Body className="text-primary" size="small">
          {t("liveUpdatesInfo.maxFee", "Max fee")}
        </Text.Body>
        <Text.Body className="text-primary" size="small">
          {formatFee(maxFeeGwei)}
        </Text.Body>
      </SpaceBetween>
      <SpaceBetween>
        <Text.Body className="mr-3 text-primary" size="small">
          {t("liveUpdatesInfo.priorityFee", "Priority fee")}
        </Text.Body>
        <Text.Body className="text-primary" size="small">
          {formatFee(priorityFeeGwei)}
        </Text.Body>
      </SpaceBetween>
      <SpaceBetween>
        <Text.Body className="text-primary" size="small">
          {t("liveUpdatesInfo.seenAt", "Time")}
        </Text.Body>
        <Text.Body className="text-primary" size="small">
          <LiveTimestamp fromDate={seenAt} />
        </Text.Body>
      </SpaceBetween>
      <SpaceBetween>
        <Text.Body className="text-primary" size="small">
          {t("liveUpdatesInfo.transaction", "TX")}
        </Text.Body>
        <Link href={blockExplorerLink}>
          <Overflow>
            <Text.Body size="small">{truncatedTx}</Text.Body>
          </Overflow>
        </Link>
      </SpaceBetween>
    </FlexColumn>
  )
}
