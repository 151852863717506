import { Flex, FlexProps, classNames } from "@opensea/ui-kit"
import { transparentize } from "polished"
import styled, { css } from "styled-components"
import { Button } from "@/design-system/Button"

export const ItemCardFullWidthCtaButtonCss = css`
  && {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: ${props => props.theme.borderRadius.default};
    border-bottom-right-radius: ${props => props.theme.borderRadius.default};
    height: 36px;
    width: 100%;
  }
`

export const ItemCardFullWidthCtaButton = styled(Button)`
  ${ItemCardFullWidthCtaButtonCss}
`

export const ItemCardMobileFullWidthCtaButton = styled(
  ItemCardFullWidthCtaButton,
)`
  &&,
  &&:hover {
    border-color: ${props =>
      props.theme.colors.components.elevation.level1.regular.background};
    background-color: ${props =>
      props.theme.colors.components.elevation.level1.regular.background};
    justify-content: flex-start;
  }
`

const SECONDARY_CTA_BUTTON_WIDTH = 52

export const ItemCardPrimaryCtaButtonCss = css`
  && {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: ${props => props.theme.borderRadius.default};
    border-bottom-right-radius: 0;

    height: 36px;
    white-space: nowrap;
    color: white;

    width: calc(100% - ${SECONDARY_CTA_BUTTON_WIDTH - 1}px);
  }
`

export const ItemCardPrimaryCtaButton = styled(Button)`
  ${ItemCardPrimaryCtaButtonCss}
`

export const ItemCardSecondaryCtaButton = styled(Button)`
  && {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: ${props => props.theme.borderRadius.default};

    // Override left border styles by default and for hover modifier
    &,
    &:hover {
      border-left: 1px solid
        ${props => transparentize(0.4, props.theme.colors.white)};
    }

    white-space: nowrap;
    height: 36px;
    color: white;

    width: ${SECONDARY_CTA_BUTTON_WIDTH}px;
  }
`

export const ItemCardCtaButtonContainer = ({
  className,
  ...props
}: FlexProps) => (
  <Flex
    {...props}
    className={classNames("overflow-hidden relative rounded-b-xl", className)}
  />
)
