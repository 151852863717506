/**
 * @generated SignedSource<<dcf33b81194b14a41712919a9b58ed1b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssetContextMenu_data$data = {
  readonly relayId: string;
  readonly " $fragmentType": "AssetContextMenu_data";
};
export type AssetContextMenu_data$key = {
  readonly " $data"?: AssetContextMenu_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssetContextMenu_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssetContextMenu_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "cabe631374b82b0cb99640eb16bebb61";

export default node;
