/**
 * @generated SignedSource<<fbbba80842e04be83e536e7d1238de54>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderV2OrderType = "BASIC" | "CRITERIA" | "DUTCH" | "ENGLISH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ItemCardFooter_bestAskV2$data = {
  readonly insertedAt: string | null;
  readonly maker: {
    readonly address: string;
  };
  readonly orderType: OrderV2OrderType;
  readonly priceType: {
    readonly unit: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"AssetSearchListViewTableQuickBuy_order" | "ItemAddToCartButton_order" | "ItemCardPrice_data" | "useIsQuickBuyEnabled_order">;
  readonly " $fragmentType": "ItemCardFooter_bestAskV2";
};
export type ItemCardFooter_bestAskV2$key = {
  readonly " $data"?: ItemCardFooter_bestAskV2$data;
  readonly " $fragmentSpreads": FragmentRefs<"ItemCardFooter_bestAskV2">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemCardFooter_bestAskV2",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "orderType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "priceType",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unit",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "maker",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ItemCardPrice_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ItemAddToCartButton_order"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssetSearchListViewTableQuickBuy_order"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useIsQuickBuyEnabled_order"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "insertedAt",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};

(node as any).hash = "079dda99b13b4e2720eebac774fddc5d";

export default node;
