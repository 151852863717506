/* eslint-disable tailwindcss/no-custom-classname */
import React, { useEffect } from "react"
import { useIsLessThanSm, Spinner, Alert } from "@opensea/ui-kit"
import styled from "styled-components"
import { getAppInitialProps } from "@/components/app/initialProps"
import { Link } from "@/components/common/Link"
import { EventHistory } from "@/components/events/EventHistory.react"
import { CollectionManager } from "@/components/layout/CollectionManager.react"
import { useChains } from "@/hooks/useChains"
import { useRouter } from "@/hooks/useRouter"
import { useTranslate } from "@/hooks/useTranslate"
import { CollectionPayoutsPageQuery } from "@/lib/graphql/__generated__/CollectionPayoutsPageQuery.graphql"
import { graphql, GraphQLInitialProps } from "@/lib/graphql/graphql"
import { GraphQLNextPage } from "@/lib/graphql/GraphQLPage.react"
import { areCreatorEarningsSupported } from "@/lib/helpers/chainUtils"
import { getCollectionUrl } from "@/lib/helpers/collection"
import QP from "@/lib/qp/qp"

export type CollectionPayoutsPageProps = {
  collectionSlug: string
}

export const CollectionPayoutPage: GraphQLNextPage<
  CollectionPayoutsPageQuery,
  CollectionPayoutsPageProps
> = ({ data, collectionSlug }) => {
  const t = useTranslate("collections")
  const isMobile = useIsLessThanSm()
  const collection = data?.collection
  const router = useRouter()
  const { getChainName } = useChains()

  useEffect(() => {
    if (collection && !collection.isEditable) {
      router.replace(`/assets/${collectionSlug}`)
    }
  }, [collection, collectionSlug, router])

  const breadcrumbLinks = [
    {
      href: "/collections",
      label: "My Collections",
    },
    {
      href: collection ? getCollectionUrl(collection) : "/collections",
      label: collection?.name || t("payouts.collection", "Collection"),
    },
  ]

  if (!collection?.isEditable) {
    return (
      <CollectionManager breadcrumbLinks={breadcrumbLinks}>
        <DivContainer>
          <div className="collectionManagerPayouts--loading-container">
            <div className="collectionManagerPayouts--loading">
              {collection && (
                <div>
                  <header className="collectionManagerPayouts--header">
                    {t("payouts.authorizing", "Authorizing...")}
                  </header>
                  <p>
                    {t(
                      "payouts.notTheOwner",
                      "Not the owner? Visit the {{collectionNameLink}}",
                      {
                        collectionNameLink: (
                          <Link href={`/assets/${collectionSlug}`}>
                            {collection.name}{" "}
                            {t("payouts.marketplace", "marketplace")}
                          </Link>
                        ),
                      },
                    )}
                  </p>
                </div>
              )}
              <div className="collectionManagerPayouts--loader">
                <Spinner />
              </div>
            </div>
          </div>
        </DivContainer>
      </CollectionManager>
    )
  }

  return (
    <CollectionManager
      breadcrumbLinks={breadcrumbLinks}
      fullWidth
      title={t("payouts.creatorEarnings.title", "Creator Earnings")}
    >
      {!areCreatorEarningsSupported(collection.defaultChain.identifier) ? (
        <Alert>
          <Alert.Content>
            <Alert.Body>
              {t(
                "payouts.creatorEarningsNotYetSupported",
                "Creator earnings are not yet supported for {{chain}}",
                { chain: getChainName(collection.defaultChain.identifier) },
              )}
            </Alert.Body>
          </Alert.Content>
        </Alert>
      ) : (
        <DivContainer>
          <EventHistory
            className="storefronManagerPayouts--event-activity"
            mode="payouts"
            scrollboxClassName="storefronManagerPayouts--event-activity-scrollbox"
            useWindow={isMobile}
            variables={{
              collections: [collectionSlug],
              eventTypes: ["AUCTION_SUCCESSFUL"],
              showAll: true,
            }}
          />
        </DivContainer>
      )}
    </CollectionManager>
  )
}

const query = graphql`
  query CollectionPayoutsPageQuery($slug: CollectionSlug!) {
    collection(collection: $slug) {
      defaultChain {
        identifier
      }
      isEditable
      name
      ...collection_url
    }
  }
`

CollectionPayoutPage.getInitialProps = QP.nextParser(
  { collectionSlug: QP.string },
  async (
    { collectionSlug },
    ctx,
  ): Promise<
    GraphQLInitialProps<CollectionPayoutsPageQuery, CollectionPayoutsPageProps>
  > => {
    const variables = {
      slug: collectionSlug,
    }
    const appInitialProps = await getAppInitialProps(ctx, { query, variables })
    return {
      ...appInitialProps,
      collectionSlug,
      variables,
    }
  },
)

const DivContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-bottom: 64px;
  max-width: 100%;
  width: 1280px;

  .collectionManagerPayouts--loading-container {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;

    .collectionManagerPayouts--loading {
      margin-top: 120px;
      text-align: center;

      .collectionManagerPayouts--header {
        font-weight: 500;
        font-size: 18px;
      }

      .collectionManagerPayouts--loader {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 16px;
        width: 100%;
      }
    }
  }

  .storefronManagerPayouts--event-activity {
    width: 100%;

    .storefronManagerPayouts--event-activity-scrollbox {
      height: 800px;
      max-height: calc(100vh - 226px);
    }
  }
`
