import React, { Suspense, useCallback, useState } from "react"
import { Flex, MaterialIcon, Spinner, VerticalAligned } from "@opensea/ui-kit"
import { range } from "lodash"
import { useFragment, useLazyLoadQuery } from "react-relay"
import { useCopyToClipboard } from "react-use"
import { CreateListingButton } from "@/components/orders/CreateListingButton"
import { EditListingButton } from "@/components/orders/EditListingButton.react"
import { AcceptOfferButton } from "@/components/trade/AcceptOfferButton"
import { Source } from "@/components/trade/analytics"
import { IS_SERVER } from "@/constants/environment"
import {
  useActiveAccount,
  useWallet,
} from "@/containers/WalletProvider/WalletProvider.react"
import { useLocationContext } from "@/context/location"
import {
  Dropdown,
  DropdownContentProps,
  RenderDropdownContentProps,
  RenderItemProps,
} from "@/design-system/Dropdown"
import { DropdownList } from "@/design-system/Dropdown/elements"
import { ItemSkeleton } from "@/design-system/ItemSkeleton"
import { Tooltip, TooltipContent } from "@/design-system/Tooltip"
import { MobileDropdown } from "@/features/account/components/MobileDropdown"
import { useIsAssetSelected } from "@/features/account/hooks/useAssetSelection"
import type { AccountTab } from "@/features/account/types"
import { SelectionBatchAction } from "@/features/assets/components/AssetSelection/types"
import { useAssetVisibilityActions } from "@/features/assets/hooks/useAssetVisibilityActions"
import { AssetChangeCollectionModal } from "@/features/collections"
import { useIsSSFEnabled } from "@/hooks/useFlag"
import { useGlobalModal } from "@/hooks/useGlobalModal"
import { useRouter } from "@/hooks/useRouter"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { trackClickItemCardContextMenuOption } from "@/lib/analytics/events/itemEvents"
import { AssetContextMenu_data$key } from "@/lib/graphql/__generated__/AssetContextMenu_data.graphql"
import { AssetContextMenuMutation } from "@/lib/graphql/__generated__/AssetContextMenuMutation.graphql"
import { AssetContextMenuQuery } from "@/lib/graphql/__generated__/AssetContextMenuQuery.graphql"
import { DefaultAccountPageQuery } from "@/lib/graphql/__generated__/DefaultAccountPageQuery.graphql"
import { getNodes, graphql } from "@/lib/graphql/graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"
import { addressesEqual } from "@/lib/helpers/address"
import { getAssetEditUrl, getAssetUrl } from "@/lib/helpers/asset"

export type AssetContextMenuSelection = {
  select: () => unknown
  isSelected: boolean
  action?: SelectionBatchAction | undefined
}

type Props = Omit<DropdownContentProps, "content"> & {
  dataKey: AssetContextMenu_data$key
  source: Source
  isMobile?: boolean
  onClose?: () => void
  isOpen?: boolean
  toggleAssetSelected: () => void
}

type ImageStatus = "done" | "standby" | "wait"

type ContextItem = {
  title: string
  icon: MaterialIcon
  onClick?: () => Promise<void>
  renderContent?: (Item: typeof Dropdown.Item) => React.ReactNode
  disabled?: boolean
  href?: string
  tooltipContent?: TooltipContent
  onClickWrapper?: (
    menuItem: (onClick: () => unknown) => React.ReactNode,
  ) => React.ReactNode
}

const SKELETON_ITEM_COUNT = 4

export const AssetContextMenu = ({
  dataKey,
  source,
  isOpen,
  onClose,
  isMobile,
  toggleAssetSelected,
  ...dropdownProps
}: Props) => {
  const t = useTranslate("components")
  return isMobile ? (
    <MobileDropdown
      isOpen={!!isOpen}
      title={t("assetContextMenu.mobileDropdown.title", "More options")}
      onClose={onClose}
    >
      <Suspense
        fallback={
          <MobileDropdown.List>
            {range(SKELETON_ITEM_COUNT).map(i => (
              <ContextItemSkeleton key={i} />
            ))}
          </MobileDropdown.List>
        }
      >
        <AssetContextMenuDropdownContent
          Divider={MobileDropdown.Divider}
          Item={MobileDropdown.Item}
          List={MobileDropdown.List}
          close={() => {
            onClose?.()
          }}
          dataKey={dataKey}
          source={source}
          toggleAssetSelected={toggleAssetSelected}
        />
      </Suspense>
    </MobileDropdown>
  ) : (
    <Dropdown
      appendTo={IS_SERVER ? undefined : document.body}
      content={({ close, Divider, Item, List }) => (
        <Suspense
          fallback={
            <List>
              {range(SKELETON_ITEM_COUNT).map(i => (
                <ContextItemSkeleton key={i} />
              ))}
            </List>
          }
        >
          <AssetContextMenuDropdownContent
            Divider={Divider}
            Item={Item}
            List={List}
            close={close}
            dataKey={dataKey}
            source={source}
            toggleAssetSelected={toggleAssetSelected}
          />
        </Suspense>
      )}
      hideOnScroll
      maxHeight="100%"
      placement="top-end"
      {...dropdownProps}
    />
  )
}

type AssetContextMenuDropdownContentProps = Pick<
  Props,
  "dataKey" | "toggleAssetSelected" | "source"
> &
  Omit<RenderDropdownContentProps, "List"> & {
    List: typeof MobileDropdown.List | typeof DropdownList
  }

export const AssetContextMenuDropdownContent = ({
  close,
  dataKey,
  source,
  toggleAssetSelected,
  Divider,
  Item,
  List,
}: AssetContextMenuDropdownContentProps) => {
  const t = useTranslate("components")
  const { wallet } = useWallet()
  const { openModal } = useGlobalModal()
  const activeAccount = useActiveAccount()

  const router = useRouter()
  const pageTab = router.query["tab"] as AccountTab

  const { relayId: assetRelayId } = useFragment(
    graphql`
      fragment AssetContextMenu_data on AssetType {
        relayId
      }
    `,
    dataKey,
  )
  const { asset } = useLazyLoadQuery<AssetContextMenuQuery>(
    graphql`
      query AssetContextMenuQuery(
        $identity: IdentityInputType!
        $assetID: AssetRelayID!
      ) {
        asset(asset: $assetID) {
          relayId
          chain {
            isTradingEnabled
          }
          collection {
            isAuthorizedEditor
            assetContracts(first: 1) {
              edges {
                node {
                  __typename
                }
              }
            }
          }
          creator {
            address
          }
          imageUrl
          isCompromised
          isDelisted
          isEditable {
            value
            reason
          }
          isListable
          ownedQuantity(identity: $identity)
          ownership(identity: $identity) {
            isPrivate
          }
          tradeSummary {
            bestAsk {
              maker {
                address
              }
              ...EditListingButton_listing
            }
            bestBid {
              maker {
                address
              }
              ...AcceptOfferButton_order @arguments(identity: $identity)
            }
          }
          ...asset_edit_url
          ...asset_url
          ...itemEvents_data
          ...AcceptOfferButton_asset @arguments(identity: $identity)
          ...CreateListingButton_item
          ...EditListingButton_item
        }
      }
    `,
    {
      assetID: assetRelayId,
      identity: wallet.getActiveAccountKeyStrict(),
    },
  )

  const {
    collection: { isAuthorizedEditor, assetContracts },
    chain,
    creator,
    imageUrl,
    isCompromised: isAssetCompromised,
    isDelisted,
    isEditable,
    isListable,
    ownedQuantity,
    ownership,
    relayId,
  } = asset
  const { origin } = useLocationContext()

  const isTradingEnabled = chain.isTradingEnabled
  const ownsAsset = ownedQuantity ? +ownedQuantity > 0 : false
  const isCreatedByMe = addressesEqual(creator?.address, activeAccount?.address)
  const isActiveAccountCompromised = activeAccount?.isCompromised ?? false
  const isSharedStorefront = getNodes(assetContracts).length === 0
  const isSSFEnabled = useIsSSFEnabled()

  const [_, copy] = useCopyToClipboard()
  const url = `${origin}${getAssetUrl(asset)}`

  const { attempt, showErrorMessage, showSuccessMessage } = useToasts()
  const { mutate } = useGraphQL()
  const [pfpStatus, setPfpStatus] = useState<ImageStatus>("standby")

  const makeAssetImagePfp = useCallback(async () => {
    if (pfpStatus === "wait") {
      return
    }
    setPfpStatus("wait")

    if (!imageUrl) {
      showErrorMessage(
        t(
          "assetContextMenu.picture.error",
          "Unable to set NFT as profile picture, the content is invalid",
        ),
      )
      return
    }
    await attempt(async () => {
      await mutate<AssetContextMenuMutation>(
        graphql`
          mutation AssetContextMenuMutation(
            $input: SetNftImageAsProfilePictureMutationInput!
          ) {
            accounts {
              setNftImageAsProfilePicture(input: $input) {
                imageUrl
              }
            }
          }
        `,
        { input: { asset: relayId } },
        {
          shouldAuthenticate: true,
          updater: store => {
            if (activeAccount) {
              const accountRecord = store.get<DefaultAccountPageQuery>(
                activeAccount.relayId,
              )
              if (accountRecord) {
                accountRecord.setValue(imageUrl, "imageUrl")
              }
            }
          },
        },
      )
      showSuccessMessage(
        t(
          "assetContextMenu.picture.success",
          "Your profile picture has been successfully updated",
        ),
      )
      setPfpStatus("done")
    })
  }, [
    activeAccount,
    attempt,
    imageUrl,
    mutate,
    pfpStatus,
    relayId,
    showErrorMessage,
    showSuccessMessage,
    t,
  ])

  const isSelected = useIsAssetSelected(asset.relayId)

  const itemGroup1: ContextItem[] = []
  if (ownership) {
    itemGroup1.push({
      icon: "ads_click",
      title: isSelected
        ? t("assetContextMenu.deselect.title", "Deselect")
        : t("assetContextMenu.select.title", "Select"),
      onClick: async () => {
        toggleAssetSelected()
        trackClickItemCardContextMenuOption(asset, { option: "select" })
      },
    })
  }

  const itemGroup2: ContextItem[] = []
  if (
    isTradingEnabled &&
    ownership &&
    !isDelisted &&
    isListable &&
    !isActiveAccountCompromised
  ) {
    const { bestAsk, bestBid } = asset.tradeSummary
    if (bestAsk && bestAsk.maker.address === activeAccount?.address) {
      itemGroup2.push({
        icon: "edit",
        title: t("assetContextMenu.editListing.title", "Edit listing"),
        onClick: async () => {
          trackClickItemCardContextMenuOption(asset, {
            option: "editListing",
          })
        },
        onClickWrapper: menuItem => (
          <EditListingButton
            item={asset}
            listing={bestAsk}
            source={source}
            trigger={menuItem}
          />
        ),
      })
    } else {
      itemGroup2.push({
        icon: "sell",
        title: t("assetContextMenu.listForSale.title", "List for sale"),
        onClick: async () => {
          trackClickItemCardContextMenuOption(asset, {
            option: "sell",
          })
        },
        onClickWrapper: menuItem => (
          <CreateListingButton
            item={asset}
            source={source}
            trigger={menuItem}
          />
        ),
      })
    }
    if (bestBid && bestBid.maker.address !== activeAccount?.address) {
      itemGroup2.push({
        icon: "price_check",
        title: t("assetContextMenu.acceptBestOffer.title", "Accept best offer"),
        onClick: async () => {
          trackClickItemCardContextMenuOption(asset, {
            option: "acceptOffer",
          })
        },
        onClickWrapper: menuItem => (
          <AcceptOfferButton
            criteriaAsset={asset}
            order={bestBid}
            source={source}
            trigger={menuItem}
          />
        ),
      })
    }
  }

  const itemGroup3: ContextItem[] = []
  const { hideAssets, unhideAssets } = useAssetVisibilityActions()
  if (pageTab !== "created" && ownership) {
    if (ownership.isPrivate) {
      itemGroup3.push({
        icon: "visibility",
        title: t("assetContextMenu.unhide.title", "Unhide"),
        onClick: async () => {
          trackClickItemCardContextMenuOption(asset, {
            option: "unhide",
          })
          await unhideAssets([assetRelayId])
        },
      })
    } else {
      itemGroup3.push({
        icon: "visibility_off",
        title: t("assetContextMenu.hide.title", "Hide"),
        onClick: async () => {
          trackClickItemCardContextMenuOption(asset, {
            option: "hide",
          })
          await hideAssets([assetRelayId])
        },
      })
    }
  }

  if (ownership && !isDelisted && !isActiveAccountCompromised) {
    itemGroup3.push({
      icon: "send",
      title: t("assetContextMenu.transfer.title", "Transfer"),
      onClick: async () => {
        trackClickItemCardContextMenuOption(asset, { option: "transfer" })
        await router.push(getAssetUrl(asset, "transfer"))
      },
      disabled: isAssetCompromised,
      tooltipContent: isAssetCompromised
        ? t(
            "assetContextMenu.compromised",
            "This item has been flagged for suspicious activity and cannot be transferred using OpenSea.",
          )
        : undefined,
    })
  }

  if (ownsAsset) {
    itemGroup3.push({
      disabled: pfpStatus === "wait",
      onClick: async () => {
        await makeAssetImagePfp()
        trackClickItemCardContextMenuOption(asset, {
          option: "setAsProfilePicture",
        })
      },
      icon: "image",
      title: t("assetContextMenu.picture.title", "Make profile picture"),
      renderContent: Item => {
        return (
          <Flex className="items-center">
            <Item.Title>
              {t("assetContextMenu.picture.title", "Make profile picture")}
            </Item.Title>
            {pfpStatus === "wait" ? (
              <VerticalAligned className="ml-1">
                <Spinner />
              </VerticalAligned>
            ) : null}
          </Flex>
        )
      },
    })
  }

  if (!isDelisted) {
    itemGroup3.push({
      icon: "link",
      title: t("assetContextMenu.copyLink.title", "Copy link"),
      onClick: async () => {
        copy(url)
        showSuccessMessage(
          t("assetContextMenu.copyLink.success", "Link copied"),
        )
        trackClickItemCardContextMenuOption(asset, { option: "copyLink" })
      },
    })
  }

  const itemGroup4: ContextItem[] = []
  if (isSSFEnabled && (isCreatedByMe || isEditable.value)) {
    itemGroup4.push({
      icon: "edit",
      title: t("assetContextMenu.editItem.title", "Edit item"),
      disabled: !isEditable.value,
      href: getAssetEditUrl(asset),
      tooltipContent: isEditable.reason,
      onClick: async () => {
        trackClickItemCardContextMenuOption(asset, { option: "editAsset" })
      },
    })
  }

  if (isSSFEnabled && isCreatedByMe && isAuthorizedEditor) {
    itemGroup4.push({
      icon: "swap_vertical_circle",
      title: t("assetContextMenu.changeCollection.title", "Change collection"),
      onClick: async () => {
        openModal(
          close => (
            <AssetChangeCollectionModal
              assets={[asset.relayId]}
              isSharedStorefront={isSharedStorefront}
              onSuccess={close}
            />
          ),
          {},
        )
        trackClickItemCardContextMenuOption(asset, {
          option: "changeCollection",
        })
      },
    })
  }

  const renderedItems: React.ReactNode[] = []
  const itemGroups = [itemGroup1, itemGroup2, itemGroup3, itemGroup4].filter(
    itemGroup => itemGroup.length,
  )
  itemGroups.forEach((group, groupIndex) => {
    group.forEach((item, itemIndex) => {
      const isLastItem = itemIndex === group.length - 1

      renderedItems.push(
        <RenderContextItem
          Item={Item}
          close={close}
          extraClassNames={isLastItem ? "MobileDropdown-group-last" : ""}
          item={item}
          key={item.title}
        />,
      )
    })
    const isLastGroup = groupIndex === itemGroups.length - 1
    if (!isLastGroup && Divider) {
      renderedItems.push(<Divider key={groupIndex} />)
    }
  })

  return (
    <List data-testid="AssetContextMenuDropdownContent">{renderedItems}</List>
  )
}

const RenderContextItem = ({
  Item,
  item,
  close,
  extraClassNames,
}: RenderItemProps<ContextItem> & { extraClassNames: string }) => {
  const menuItemProps = {
    key: item.title,
    onClick: async (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      close()
      if (item.onClick) {
        await item.onClick()
      }
    },
    ...(item.disabled && { disabled: true }),
    ...(item.href && { href: item.href }),
  }

  const content = item.renderContent ? (
    item.renderContent(Item)
  ) : (
    <Item.Title>{item.title}</Item.Title>
  )

  const menuItem = item.onClickWrapper ? (
    <>
      {item.onClickWrapper(onClick => (
        <Item
          className={extraClassNames}
          {...menuItemProps}
          onClick={e => {
            e.stopPropagation()
            close()
            if (item.onClick) {
              item.onClick()
            }
            onClick()
          }}
        >
          <Item.Avatar icon={item.icon} />
          <Item.Content>{content}</Item.Content>
        </Item>
      ))}
    </>
  ) : (
    <Item {...menuItemProps} className={extraClassNames}>
      <Item.Avatar icon={item.icon} />
      <Item.Content>{content}</Item.Content>
    </Item>
  )

  if (item.tooltipContent) {
    return (
      <Tooltip content={item.tooltipContent} key={item.title}>
        {menuItem}
      </Tooltip>
    )
  }
  return menuItem
}

export const ContextItemSkeleton = () => {
  return (
    <ItemSkeleton>
      <ItemSkeleton.Avatar />
      <ItemSkeleton.Content>
        <ItemSkeleton.Title />
      </ItemSkeleton.Content>
    </ItemSkeleton>
  )
}
