/**
 * @generated SignedSource<<befcc8eeb9c167120f09c3c82f57cd0b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ItemCardPrice_data$data = {
  readonly payment: {
    readonly symbol: string;
  };
  readonly perUnitPriceType: {
    readonly unit: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"useIsQuickBuyEnabled_order">;
  readonly " $fragmentType": "ItemCardPrice_data";
};
export type ItemCardPrice_data$key = {
  readonly " $data"?: ItemCardPrice_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"ItemCardPrice_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemCardPrice_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "perUnitPriceType",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unit",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentAssetType",
      "kind": "LinkedField",
      "name": "payment",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "symbol",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useIsQuickBuyEnabled_order"
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};

(node as any).hash = "51735dacb6cb7298cbb7a139c28acdf6";

export default node;
