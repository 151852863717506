import React, { ReactNode, useCallback, useMemo } from "react"
import { createContext } from "use-context-selector"
import {
  StoredItem,
  useAssetSelectionStateStorage,
  useAssetSelectionStorage,
} from "@/features/assets/hooks/useAssetSelectionStorage"

type ContextType = {
  selectedIds: readonly string[]
  selectedItems: readonly StoredItem[]
  onClose: () => unknown
  onDelete: (itemRelayIds: string[]) => unknown
  onDeleteAll: () => unknown
  onSelect: (item: StoredItem) => unknown
  onBatchSelect: (items: StoredItem[]) => unknown
}

export const AssetSelectionContext = createContext<ContextType>({
  selectedIds: [],
  selectedItems: [],
  onClose: () => null,
  onDelete: () => null,
  onDeleteAll: () => null,
  onSelect: () => null,
  onBatchSelect: () => null,
})

type BaseProviderProps = {
  children: ReactNode
} & ReturnType<typeof useAssetSelectionStorage>

type LocalStorageBasedProviderProps = {
  children: ReactNode
}

type StateBasedProviderProps = LocalStorageBasedProviderProps & {
  initialSelectedItems?: StoredItem[]
}

const BaseProvider = ({
  children,
  selectedIds,
  selectedItems,
  batchSelect,
  select,
  unselect,
  unselectAll,
  clear,
}: BaseProviderProps) => {
  const onClose = useCallback(() => clear(), [clear])

  const onBatchSelect = useCallback(
    (items: StoredItem[]) => {
      batchSelect(items)
    },
    [batchSelect],
  )

  const onDelete = useCallback(
    (itemRelayIds: string[]) => unselect(itemRelayIds),
    [unselect],
  )

  const value = useMemo(
    () => ({
      selectedIds,
      selectedItems,
      onClose,
      onDelete,
      onDeleteAll: unselectAll,
      onSelect: select,
      onBatchSelect,
    }),
    [
      selectedIds,
      selectedItems,
      onClose,
      onDelete,
      unselectAll,
      select,
      onBatchSelect,
    ],
  )

  return (
    <AssetSelectionContext.Provider value={value}>
      {children}
    </AssetSelectionContext.Provider>
  )
}

// Default provider persists selections in local storage (historical).
const LocalStorageBasedProvider = ({
  children,
}: LocalStorageBasedProviderProps) => {
  const selectionProps = useAssetSelectionStorage()
  return <BaseProvider {...selectionProps}>{children}</BaseProvider>
}

// Prefer to use state-based context provider when local storage is unnecessary.
// Avoids the need to clear local storage when selections are no longer needed.
const StateBasedContextProvider = ({
  children,
  initialSelectedItems,
}: StateBasedProviderProps) => {
  const { selectedItems, ...selectionProps } =
    useAssetSelectionStateStorage(initialSelectedItems)

  const selectedIds = useMemo(
    () => selectedItems.map(item => item.relayId),
    [selectedItems],
  )

  return (
    <BaseProvider
      {...selectionProps}
      selectedIds={selectedIds}
      selectedItems={selectedItems}
    >
      {children}
    </BaseProvider>
  )
}

export const AssetSelectionContextProvider = Object.assign(
  LocalStorageBasedProvider,
  {
    NoPersistence: StateBasedContextProvider,
  },
)
