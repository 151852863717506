/**
 * @generated SignedSource<<f3b41e5178058fc179ff8e1d393ae7cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ItemCardCta_item$data = {
  readonly __typename: string;
  readonly orderData: {
    readonly bestAskV2: {
      readonly " $fragmentSpreads": FragmentRefs<"AddToCartAndQuickBuyButton_order" | "EditListingButton_listing" | "QuickBuyButton_order">;
    } | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"AssetContextMenu_data" | "CreateListingButton_item" | "EditListingButton_item" | "itemEvents_dataV2" | "useItemCardCta_item">;
  readonly " $fragmentType": "ItemCardCta_item";
};
export type ItemCardCta_item$key = {
  readonly " $data"?: ItemCardCta_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"ItemCardCta_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "identity"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showContextMenu"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemCardCta_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ESOrderDataType",
      "kind": "LinkedField",
      "name": "orderData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderV2Type",
          "kind": "LinkedField",
          "name": "bestAskV2",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AddToCartAndQuickBuyButton_order"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EditListingButton_listing"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "QuickBuyButton_order"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "showContextMenu",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AssetContextMenu_data"
        }
      ]
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "identity",
          "variableName": "identity"
        },
        {
          "kind": "Variable",
          "name": "showContextMenu",
          "variableName": "showContextMenu"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useItemCardCta_item"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "itemEvents_dataV2",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "relayId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ChainType",
          "kind": "LinkedField",
          "name": "chain",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "identifier",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tokenId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetContractType",
              "kind": "LinkedField",
              "name": "assetContract",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "address",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "AssetType",
          "abstractKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateListingButton_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditListingButton_item"
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};

(node as any).hash = "430c46634482eef3d32f81ed2f444523";

export default node;
