import { useFragment } from "react-relay"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { useHasOneOrMoreAssetsSelected } from "@/features/account/hooks/useAssetSelection"
import { useSweepFormIsSweepModeToggled } from "@/features/sweep/SweepContextProvider.react"
import { useIsSeaportEnabled } from "@/hooks/useIsSeaportEnabled"
import { useItemCardCta_item$key } from "@/lib/graphql/__generated__/useItemCardCta_item.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { addressesEqual } from "@/lib/helpers/address"
import { isSolana } from "@/lib/helpers/chainUtils"
import { bn } from "@/lib/helpers/numberUtils"

// TODO: ideally we could get these from context
type UseItemCardCtaOptions = {
  showContextMenu?: boolean
}

export const useItemCardCta = (
  itemKey: useItemCardCta_item$key | null,
  { showContextMenu }: UseItemCardCtaOptions = {},
) => {
  const connectedAddress = useConnectedAddress()
  const isSelectionActive = useHasOneOrMoreAssetsSelected()
  const isSweepModeToggled = useSweepFormIsSweepModeToggled()

  const item = useFragment(
    graphql`
      fragment useItemCardCta_item on ItemType
      @argumentDefinitions(
        identity: { type: "IdentityInputType" }
        showContextMenu: { type: "Boolean!" }
      ) {
        __typename
        chain {
          identifier
          isTradingEnabled
        }
        orderData {
          bestAskV2 {
            orderType
            maker {
              address
            }
          }
        }
        ... on AssetType {
          isDelisted
          isListable
          isCurrentlyFungible
          # Only request ownership information when showContextMenu is true (on account page) since it prevents caching
          ownedQuantity(identity: $identity) @include(if: $showContextMenu)
        }
      }
    `,
    itemKey,
  )
  const isTradingEnabled = item?.chain.isTradingEnabled

  // Context menu is only available in some search views that pass showContextMenu,
  // e.g. Account page. It is not supported for bundles.
  const isContextMenuAvailable =
    !!showContextMenu && item?.__typename === "AssetType"

  const orderType = item?.orderData.bestAskV2?.orderType
  // Restricted orders can only be executed by the offerer or the zone, so they
  // cannot be fulfilled immediately by the viewer via cart, quick buy or buy now
  const isRestricted = orderType === "ENGLISH"

  // The viewer is the owner of the best ask order
  const isBestAskOwnedByViewer = addressesEqual(
    item?.orderData.bestAskV2?.maker.address,
    connectedAddress,
  )

  // Add to cart and quick buy are only available for seaport listings that are
  // not restricted
  const isSeaportEnabled = useIsSeaportEnabled(item?.chain.identifier)
  const itemIsListed = !!item?.orderData.bestAskV2
  const isAddToCartAvailable =
    isSeaportEnabled && itemIsListed && !isRestricted && !isBestAskOwnedByViewer

  // Buy now is available for all non-restricted single asset listings
  const isBuyNowAvailable =
    itemIsListed &&
    item.__typename === "AssetType" &&
    !isRestricted &&
    !isBestAskOwnedByViewer

  // This is only accurate when showContextMenu is true and ownership information
  // is requested
  const isItemOwnedByViewer =
    item?.__typename === "AssetType"
      ? !!item.ownedQuantity && !bn(item.ownedQuantity).isZero()
      : item?.__typename === "AssetBundleType"
      ? addressesEqual(
          item.orderData.bestAskV2?.maker.address,
          connectedAddress,
        )
      : false

  // Only allow quick list/lower price in contexts where showContextMenu is true
  // since ownership information is required to determine if viewer can list
  const isListingContext = !!showContextMenu

  // Available if item is owned by the viewer and not already listed by the viewer.
  const isListForSaleAvailable =
    isTradingEnabled &&
    isItemOwnedByViewer &&
    !isBestAskOwnedByViewer &&
    item.isListable &&
    isListingContext

  // Available if item is owned by the viewer and already listed by the viewer.
  // ERC1155s with multiple quantity and solana items are not supported
  const isEditListingAvailable =
    isItemOwnedByViewer &&
    isBestAskOwnedByViewer &&
    isListingContext &&
    !item?.isCurrentlyFungible &&
    !isSolana(item?.chain.identifier)

  return {
    isListForSaleAvailable,
    isEditListingAvailable,
    isContextMenuAvailable,
    isAddToCartAvailable,
    isBuyNowAvailable,
    isCtaAvailable:
      !isSelectionActive &&
      !isSweepModeToggled &&
      !item?.isDelisted &&
      (isListForSaleAvailable ||
        isEditListingAvailable ||
        isAddToCartAvailable ||
        isBuyNowAvailable ||
        isContextMenuAvailable),
  }
}
