/* eslint-disable tailwindcss/no-custom-classname */
import React, { useState } from "react"
import { classNames } from "@opensea/ui-kit"
import { isNumber } from "lodash"
import styled from "styled-components"
import { ResponsiveValue, Theme, TLengthStyledSystem } from "styled-system"
import { Overflow } from "@/components/common/Overflow"
import { Block } from "@/design-system/Block"
import {
  PriceText,
  PriceTextFiatProps,
  PriceTextTokenProps,
} from "@/design-system/PriceText"
import { Tooltip } from "@/design-system/Tooltip"
import { useTranslate } from "@/hooks/useTranslate"
import { NumberInput } from "@/lib/helpers/numberUtils"

// NOTE: These pulse components should ONLY contain background, border, and text color
// styles, as it could be used to animate color flashes in multiple contexts.
// We need four separate animation keyframes so that we can swap out alternating
// CSS rules, guaranteeing that the animation is scheduled correctly for any
// sequence of updates.
const PulseBackground = styled(Block)`
  opacity: 0;
  &.background1 {
    animation: 1s background1 1;
  }
  &.background2 {
    animation: 1s background2 1;
  }
  @keyframes background1 {
    0% {
      animation-timing-function: ease-in;
    }
    10% {
      animation-timing-function: ease-out;
      opacity: 0.24;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes background2 {
    0% {
      animation-timing-function: ease-in;
    }
    10% {
      animation-timing-function: ease-out;
      opacity: 0.24;
    }
    100% {
      opacity: 0;
    }
  }
`

const PulseText = styled(Block)`
  color: ${props => props.theme.colors.text.primary};
  &.up1 {
    animation: 6s textUp1 1;
  }
  &.down1 {
    animation: 6s textDown1 1;
  }
  &.up2 {
    animation: 6s textUp2 1;
  }
  &.down2 {
    animation: 6s textDown2 1;
  }
  @keyframes textUp1 {
    0% {
      animation-timing-function: ease-in;
      color: ${props => props.theme.colors.text.primary};
    }
    2% {
      animation-timing-function: ease-out;
      color: ${props => props.theme.colors.greenBase};
    }
    95% {
      animation-timing-function: linear;
      color: ${props => props.theme.colors.greenBase};
    }
    100% {
      color: ${props => props.theme.colors.text.primary};
    }
  }
  @keyframes textDown1 {
    0% {
      animation-timing-function: ease-in;
      color: ${props => props.theme.colors.text.primary};
    }
    2% {
      animation-timing-function: ease-out;
      color: ${props => props.theme.colors.redBase};
    }
    95% {
      animation-timing-function: linear;
      color: ${props => props.theme.colors.redBase};
    }
    100% {
      color: ${props => props.theme.colors.text.primary};
    }
  }
  @keyframes textUp2 {
    0% {
      animation-timing-function: ease-in;
      color: ${props => props.theme.colors.text.primary};
    }
    2% {
      animation-timing-function: ease-out;
      color: ${props => props.theme.colors.greenBase};
    }
    95% {
      animation-timing-function: linear;
      color: ${props => props.theme.colors.greenBase};
    }
    100% {
      color: ${props => props.theme.colors.text.primary};
    }
  }
  @keyframes textDown2 {
    0% {
      animation-timing-function: ease-in;
      color: ${props => props.theme.colors.text.primary};
    }
    2% {
      animation-timing-function: ease-out;
      color: ${props => props.theme.colors.redBase};
    }
    95% {
      animation-timing-function: linear;
      color: ${props => props.theme.colors.redBase};
    }
    100% {
      color: ${props => props.theme.colors.text.primary};
    }
  }
`

type ResponsiveValueAlias = ResponsiveValue<
  string | number | symbol,
  Required<Theme<TLengthStyledSystem>>
>
type PriceTickerProps = {
  className?: string
  margin?: ResponsiveValueAlias
  noTooltip?: boolean
  padding?: ResponsiveValueAlias
}

function usePriceTicker(
  props: (PriceTextTokenProps | PriceTextFiatProps) & PriceTickerProps,
) {
  const isFiat = "usd" in props
  const value = isFiat ? props.usd : props.unit

  const [state, setState] = useState<{
    value: NumberInput | null | undefined
    previous: NumberInput | null | undefined
    pulse: "up1" | "up2" | "down1" | "down2" | undefined
    tick: number
  }>({
    value,
    previous: value,
    pulse: undefined,
    tick: 0,
  })

  if (isNumber(value) && isNumber(state.value) && value !== state.value) {
    setState({
      value,
      previous: state.value,
      pulse:
        value > state.value
          ? state.tick % 2
            ? "up1"
            : "up2"
          : state.tick % 2
          ? "down1"
          : "down2",
      tick: state.tick + 1,
    })
  }
  return state
}

function PriceTickerBase(
  props: (PriceTextTokenProps | PriceTextFiatProps) & PriceTickerProps,
) {
  const t = useTranslate("common")
  const { previous, pulse } = usePriceTicker(props)
  const { margin, padding, noTooltip, ...restProps } = props
  const isFiat = "usd" in restProps

  const priceText = (
    <Overflow>
      {isFiat ? (
        <PriceText.Fiat {...restProps} />
      ) : (
        <PriceText.Token {...restProps} />
      )}
    </Overflow>
  )
  const contents = (
    <Block className="relative" width="fit-content">
      <PulseText
        className={classNames("rounded-lg", pulse)}
        margin={margin || "0 0 0 -4px"}
        padding={padding || "0 4px"}
      >
        {priceText}
      </PulseText>
      <PulseBackground
        className={classNames(
          "absolute left-0 top-0 rounded-lg",
          pulse
            ? ["up1", "down1"].includes(pulse)
              ? "background1"
              : "background2"
            : undefined,
          pulse
            ? ["up1", "up2"].includes(pulse)
              ? "bg-green-3"
              : "bg-red-3"
            : undefined,
        )}
        margin={margin || "0 0 0 -4px"}
        padding={padding || "0 4px"}
        style={{ color: "rgba(0,0,0,0)" }}
      >
        {priceText}
      </PulseBackground>
    </Block>
  )

  return noTooltip ? (
    contents
  ) : (
    <Tooltip
      content={
        pulse && previous ? (
          <>
            {t("priceTicker.previous", "Previous Price")}:{" "}
            {isFiat ? (
              <PriceText.Fiat {...restProps} usd={previous} />
            ) : (
              <PriceText.Token {...restProps} unit={previous} />
            )}
          </>
        ) : null
      }
    >
      {contents}
    </Tooltip>
  )
}

const PriceTickerToken = (props: PriceTextTokenProps & PriceTickerProps) => (
  <PriceTickerBase {...props} />
)

const PriceTickerFiat = (props: PriceTextFiatProps & PriceTickerProps) => (
  <PriceTickerBase {...props} />
)

export const PriceTicker = Object.assign(PriceTickerToken, {
  Fiat: PriceTickerFiat,
  Token: PriceTickerToken,
})
