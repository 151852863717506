/**
 * @generated SignedSource<<a277485604aa01d25df46eb1db2ec4cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ItemMetadata$data = {
  readonly __typename: string;
  readonly assetEventData: {
    readonly lastSale: {
      readonly unitPriceQuantity: {
        readonly asset: {
          readonly decimals: number | null;
          readonly symbol: string | null;
        };
        readonly quantity: string;
        readonly " $fragmentSpreads": FragmentRefs<"AssetQuantity_data">;
      } | null;
    } | null;
  };
  readonly bestAllTypeBid?: {
    readonly perUnitPriceType: {
      readonly symbol: string;
      readonly unit: string;
    };
  } | null;
  readonly mintEvent?: {
    readonly perUnitPrice: {
      readonly symbol: string;
      readonly unit: string;
    } | null;
  } | null;
  readonly orderData: {
    readonly bestAskForOwnerItemCard?: {
      readonly closedAt: string | null;
      readonly createdDate: string;
      readonly openedAt: string;
    } | null;
    readonly bestAskV2: {
      readonly closedAt: string | null;
      readonly createdDate: string;
      readonly openedAt: string;
    } | null;
  };
  readonly " $fragmentType": "ItemMetadata";
};
export type ItemMetadata$key = {
  readonly " $data"?: ItemMetadata$data;
  readonly " $fragmentSpreads": FragmentRefs<"ItemMetadata">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "openedAt",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "createdDate",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "closedAt",
    "storageKey": null
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unit",
    "storageKey": null
  },
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "identity"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "shouldShowBestBid"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showBestAskForOwner"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemMetadata",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ESOrderDataType",
      "kind": "LinkedField",
      "name": "orderData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderV2Type",
          "kind": "LinkedField",
          "name": "bestAskV2",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "condition": "showBestAskForOwner",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "bestAskForOwnerItemCard",
              "args": [
                {
                  "kind": "Variable",
                  "name": "byAddress",
                  "variableName": "identity"
                }
              ],
              "concreteType": "OrderV2Type",
              "kind": "LinkedField",
              "name": "bestAskV2",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ESAssetEventDataType",
      "kind": "LinkedField",
      "name": "assetEventData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ESAssetEventType",
          "kind": "LinkedField",
          "name": "lastSale",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetQuantityType",
              "kind": "LinkedField",
              "name": "unitPriceQuantity",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AssetQuantity_data"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quantity",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetType",
                  "kind": "LinkedField",
                  "name": "asset",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "decimals",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "condition": "shouldShowBestBid",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderV2Type",
              "kind": "LinkedField",
              "name": "bestAllTypeBid",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriceType",
                  "kind": "LinkedField",
                  "name": "perUnitPriceType",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetEventType",
              "kind": "LinkedField",
              "name": "mintEvent",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriceType",
                  "kind": "LinkedField",
                  "name": "perUnitPrice",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "type": "AssetType",
      "abstractKey": null
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};
})();

(node as any).hash = "019b166218336184a0f507eab858871f";

export default node;
