import React, { useMemo, useRef } from "react"
import { CenterAligned, Flex } from "@opensea/ui-kit"
import { useWindowSize } from "@react-hook/window-size"
import {
  RenderComponentProps,
  useContainerPosition,
  useInfiniteLoader,
  UseInfiniteLoaderOptions,
  useMasonry,
  UseMasonryOptions,
  usePositioner,
  useResizeObserver,
  useScroller,
} from "masonic"
import {
  CAROUSEL_RESPONSIVE_CONFIG_DEFAULT,
  Carousel,
} from "@/design-system/Carousel"
import { getLoadMoreFromPagination } from "@/design-system/utils"
import { useIsMounted } from "@/hooks/useIsMounted"
import { useMasonicRerenderKey } from "@/hooks/useMasonicRerenderKey"
import { useSize } from "@/hooks/useSize"
import { UnreachableCaseError } from "../../lib/helpers/type"
import { Block } from "../Block"
import { MasonryProps } from "../Masonry"
import {
  ScrollingPaginator,
  ScrollingPaginatorProps,
} from "../ScrollingPaginator"
import { calculateItemWidth } from "./calculateItemWidth"
import { GalleryVariant } from "./types"

export type GalleryProps<T> = {
  gridGap: number
  itemMinWidth: number
  items: T[]
  getKey: (item: T, index: number) => React.Key
  variant?: GalleryVariant
  pagination?: Omit<ScrollingPaginatorProps, "intersectionOptions">
  sidePadding?: number
  evenSidePadding?: boolean
  isLoading?: boolean
  renderItem: React.ComponentType<
    RenderComponentProps<T> & {
      containerWidth?: number
      // TODO (clean this up as it's used for a different grid, we're just reusing types improperly)
      fillContainerWidth?: boolean
      showQuantityBadge?: boolean
      showAssetMediaEditions?: boolean
      sizes?: string
    }
  >
} & Pick<UseInfiniteLoaderOptions<T>, "isItemLoaded" | "threshold"> &
  Pick<
    MasonryProps<T>,
    "itemHeightEstimate" | "ssrHeight" | "ssrWidth" | "overscanBy"
  >

export const Gallery = <T,>({
  gridGap,
  itemMinWidth,
  items,
  getKey,
  renderItem,
  sidePadding,
  evenSidePadding,
  variant = "horizontal",
  pagination,
  isItemLoaded,
  threshold,
  isLoading = false,
  ...rest
}: GalleryProps<T>) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [containerWidth] = useSize(containerRef)
  const { itemWidth, numItems: itemsPerRow } = useMemo(
    () =>
      calculateItemWidth({
        gridGap,
        width: containerWidth,
        itemMinWidth,
        sidePadding,
        evenSidePadding,
        variant,
      }),
    [
      gridGap,
      containerWidth,
      itemMinWidth,
      sidePadding,
      evenSidePadding,
      variant,
    ],
  )

  const padding = `${gridGap}px`
  const flexWrapPadding = `${gridGap / 2}px`

  const maybeLoadMore = useInfiniteLoader(
    getLoadMoreFromPagination(pagination),
    {
      isItemLoaded,
      minimumBatchSize: pagination?.size ?? 16,
      // As a default, load when we're two rows before the last element
      threshold: threshold ?? 2 * itemsPerRow + 1,
    },
  )
  const onRender: UseMasonryOptions<T>["onRender"] = (...params) => {
    if (isLoading) {
      return
    }
    maybeLoadMore(...params)
  }

  const rootMargin = `${2 * itemWidth}px`

  const Item = renderItem

  const [windowWidth, height] = useWindowSize()

  const { offset, width } = useContainerPosition(containerRef, [
    windowWidth,
    height,
    containerWidth,
  ])

  const { scrollTop, isScrolling } = useScroller(offset)

  const rerenderKey = useMasonicRerenderKey(items)

  const positioner = usePositioner(
    { width, columnGutter: gridGap, columnCount: itemsPerRow },
    [rerenderKey],
  )

  const resizeObserver = useResizeObserver(positioner)

  const isMounted = useIsMounted()

  const paddingStyle =
    variant === "flex-wrap"
      ? {
          padding: flexWrapPadding,
        }
      : { padding }

  const renderSsrCompatibleItems = (alwaysPreservePadding = false) => (
    <>
      {items.map((item, index) => (
        <Block
          key={getKey(item, index)}
          // eslint-disable-next-line jsx-a11y/aria-role
          role="card"
          style={isMounted || alwaysPreservePadding ? paddingStyle : undefined}
        >
          <Item
            containerWidth={containerWidth}
            data={item}
            index={index}
            width={itemWidth}
          />
        </Block>
      ))}
      {pagination && (
        <ScrollingPaginator
          {...pagination}
          intersectionOptions={{
            rootMargin,
            root: containerRef.current,
          }}
        />
      )}
    </>
  )

  const masonryGrid = useMasonry({
    positioner,
    scrollTop,
    isScrolling,
    height,
    containerRef,
    items,
    itemKey: getKey,
    resizeObserver,
    render: renderItem,
    onRender,
    ...rest,
  })

  switch (variant) {
    case "carousel":
      return (
        <Block
          overflow="visible"
          paddingX={evenSidePadding ? `${gridGap}px` : undefined}
          ref={containerRef}
        >
          <Carousel
            centeredSlides
            centeredSlidesBounds
            enableArrowControls
            enableFreeScroll
            enableMousewheel
            id="gallery-carousel"
            renderSlide={({ collection }) => collection}
            responsiveConfig={CAROUSEL_RESPONSIVE_CONFIG_DEFAULT}
            showScrollbar={false}
            slides={items.map((item, index) => ({
              id: `gallery-carousel-slide-${index}`,
              collection: (
                <div key={getKey(item, index)}>
                  <CenterAligned>
                    <Item
                      containerWidth={containerWidth}
                      data={item}
                      index={index}
                      width={itemWidth}
                    />
                  </CenterAligned>
                </div>
              ),
            }))}
            variant="default"
          />
        </Block>
      )
    case "grid":
      return (
        <Block className="w-full" ref={containerRef}>
          {isMounted ? (
            masonryGrid
          ) : (
            <Block
              style={{
                display: "grid",
                gridTemplateColumns: `repeat(auto-fill, minmax(${itemWidth}px, 1fr))`,
                gridGap: padding,
                width: "100%",
              }}
            >
              {renderSsrCompatibleItems()}
            </Block>
          )}
        </Block>
      )
    case "horizontal":
      return (
        <Block overflow="auto" ref={containerRef}>
          <Block
            display="inline-flex"
            style={{ padding: evenSidePadding ? `0 ${gridGap}px` : undefined }}
          >
            {renderSsrCompatibleItems()}
          </Block>
        </Block>
      )
    case "flex-wrap":
      return (
        <Flex className="h-full w-full flex-wrap" ref={containerRef}>
          {renderSsrCompatibleItems(true)}
        </Flex>
      )
    default:
      throw new UnreachableCaseError(variant)
  }
}
