/**
 * @generated SignedSource<<de40826ff73ab801356d191f4801c7ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SetNftImageAsProfilePictureMutationInput = {
  asset: string;
  clientMutationId?: string | null;
};
export type AssetContextMenuMutation$variables = {
  input: SetNftImageAsProfilePictureMutationInput;
};
export type AssetContextMenuMutation$data = {
  readonly accounts: {
    readonly setNftImageAsProfilePicture: {
      readonly imageUrl: string;
    };
  };
};
export type AssetContextMenuMutation = {
  response: AssetContextMenuMutation$data;
  variables: AssetContextMenuMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AssetContextMenuMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountMutationType",
        "kind": "LinkedField",
        "name": "accounts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AccountType",
            "kind": "LinkedField",
            "name": "setNftImageAsProfilePicture",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AssetContextMenuMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountMutationType",
        "kind": "LinkedField",
        "name": "accounts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AccountType",
            "kind": "LinkedField",
            "name": "setNftImageAsProfilePicture",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3bb72625d1599da8c098f8d2605df38f",
    "id": null,
    "metadata": {},
    "name": "AssetContextMenuMutation",
    "operationKind": "mutation",
    "text": "mutation AssetContextMenuMutation(\n  $input: SetNftImageAsProfilePictureMutationInput!\n) {\n  accounts {\n    setNftImageAsProfilePicture(input: $input) {\n      imageUrl\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2b139ba8bd82215174874b8c0df694ab";

export default node;
