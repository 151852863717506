import { graphql, useFragment } from "react-relay"
import { useAccountOrCollectionPageContext } from "@/components/layout/AccountOrCollectionPage/hooks/useAccountOrCollectionPageContext"
import { useIsLiveUpdatesEnabledForCollection_collection$key } from "@/lib/graphql/__generated__/useIsLiveUpdatesEnabledForCollection_collection.graphql"

// Within-collection filter for live updates based on search state
export const useIsLiveUpdatesEnabledForCollection = (
  collectionDataKey: useIsLiveUpdatesEnabledForCollection_collection$key | null,
) => {
  const { isSearchStateAcceptableForPolling } =
    useAccountOrCollectionPageContext()
  const isCollectionEligible =
    useIsCollectionEligibleForLiveUpdates(collectionDataKey)

  const isLiveUpdatesEnabledForCollection =
    isSearchStateAcceptableForPolling && isCollectionEligible

  return isLiveUpdatesEnabledForCollection
}

// Filter for which collection is enabled based on ERC 1155
export const useIsCollectionEligibleForLiveUpdates = (
  collectionDataKey: useIsLiveUpdatesEnabledForCollection_collection$key | null,
) => {
  const collection = useFragment(
    graphql`
      fragment useIsLiveUpdatesEnabledForCollection_collection on CollectionType {
        statsV2 {
          hasFungibles
        }
      }
    `,
    collectionDataKey,
  )

  if (collectionDataKey && collection) {
    return !collection.statsV2.hasFungibles
  }

  return true
}
