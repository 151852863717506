import React from "react"
import { Icon } from "@opensea/ui-kit"

export const Checkmark = ({ size = 20 }: { size?: number }) => (
  <Icon
    aria-label="Done"
    className="text-blue-3"
    size={size}
    value="check"
    weight={600}
  />
)
