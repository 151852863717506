/* eslint-disable tailwindcss/no-custom-classname */
import React from "react"
import styled, { css } from "styled-components"
import { Button } from "@/design-system/Button"
import { useTranslate } from "@/hooks/useTranslate"
import { media } from "@/styles/styleUtils"

type AssetSearchViewNoResultsProps = {
  query: string | undefined
  showEmptyView?: boolean
  onBackClick: () => unknown
}

export const AssetSearchViewNoResults = ({
  query,
  showEmptyView = false,
  onBackClick,
}: AssetSearchViewNoResultsProps) => {
  const t = useTranslate("components")
  return (
    <StyledContainer data-testid="no-data-text">
      {query || showEmptyView ? (
        <>
          {t(
            "assetSearchViewNoResults.noResults",
            "No items found for this search",
          )}
          <div className="AssetSearchView--no-results-action-area">
            <Button
              className="AssetSearchView--no-results-cta"
              onClick={onBackClick}
            >
              {t("assetSearchViewNoResults.back", "Back to all items")}
            </Button>
          </div>
        </>
      ) : (
        t("assetSearchView.noItems", "No items to display")
      )}
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: ${props => props.theme.borderRadius.default};
  font-size: 20px;
  height: initial;
  margin-top: 32px;
  opacity: 1;
  transition:
    height 0ms 400ms,
    opacity 400ms 400ms;

  .AssetSearchView--no-results-action-area {
    margin-top: 24px;
    max-width: 200px;
  }

  .AssetSearchView--no-results-cta {
    font-size: 14px;
    font-variant-caps: initial;
    pointer-events: initial;
  }

  ${media({
    sm: css`
      font-size: 28px;
      border: 1px solid ${props => props.theme.colors.components.border.level2};
      height: 248px;
      margin: 24px 0;

      .AssetSearchView--no-results-cta {
        font-size: 16px;
      }
    `,
  })}
`
