import { useCallback, useContext } from "react"
import {
  useActiveAccount,
  useActiveIdentity,
} from "@/containers/WalletProvider/WalletProvider.react"
import { AccountPageContext } from "@/features/account/components/AccountPage/tabs/utils"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { trackSetAssetPrivacy } from "@/lib/analytics/events/itemEvents"
import { DefaultAccountPageQuery } from "@/lib/graphql/__generated__/DefaultAccountPageQuery.graphql"
import { useAssetVisibilityActionsMutation } from "@/lib/graphql/__generated__/useAssetVisibilityActionsMutation.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"

export const useAssetVisibilityActions = () => {
  const t = useTranslate("assets")
  const activeAccount = useActiveAccount()
  const { mutate } = useGraphQL()
  const { exclude, setExclude } = useContext(AccountPageContext)
  const { attempt, showSuccessMessage } = useToasts()
  const identity = useActiveIdentity()

  const updateAssetPrivacy = useCallback(
    async (assets: string[], isPrivate: boolean) => {
      const assetCount = assets.length
      trackSetAssetPrivacy({ isPrivate, numItems: assetCount })

      await attempt(async () => {
        await mutate<useAssetVisibilityActionsMutation>(
          graphql`
            mutation useAssetVisibilityActionsMutation(
              $assets: [AssetRelayID!]!
              $isPrivate: Boolean!
            ) {
              assetOwnerships {
                batchSetPrivacy(assets: $assets, isPrivate: $isPrivate)
              }
            }
          `,
          { assets, isPrivate },
          {
            shouldAuthenticate: true,
            updater: store => {
              setExclude([...exclude, ...assets])
              assets.forEach(relayId => {
                // I have no idea what this is trying to do, so I got rid of the type
                // The old type makes no sense to me
                const assetRecord = store.get(relayId)
                if (assetRecord) {
                  const ownership = assetRecord.getLinkedRecord("ownership", {
                    identity,
                  })

                  ownership?.setValue(isPrivate, "isPrivate")
                }
              })

              if (activeAccount) {
                const accountRecord = store.get<DefaultAccountPageQuery>(
                  activeAccount.relayId,
                )
                if (accountRecord) {
                  const currentCount = accountRecord.getValue(
                    "privateAssetCount",
                  ) as number
                  accountRecord.setValue(
                    currentCount + (isPrivate ? assetCount : -assetCount),
                    "privateAssetCount",
                  )
                }
              }
            },
          },
        )

        showSuccessMessage(
          isPrivate
            ? t(
                "assetActions.successMessage.visibility.hidden",
                {
                  0: "{{count}} items were hidden from your profile",
                  one: "Item was hidden from your profile",
                  other: "{{count}} items were hidden from your profile",
                },
                { count: assetCount },
              )
            : t(
                "assetActions.successMessage.visibility.unhidden",
                {
                  0: "{{count}} items were unhidden from your profile",
                  one: "Item was unhidden from your profile",
                  other: "{{count}} items were unhidden from your profile",
                },
                { count: assetCount },
              ),
        )
      })
    },
    [
      attempt,
      exclude,
      mutate,
      setExclude,
      showSuccessMessage,
      t,
      identity,
      activeAccount,
    ],
  )

  const hideAssets = useCallback(
    async (assets: string[]) => updateAssetPrivacy(assets, true),
    [updateAssetPrivacy],
  )

  const unhideAssets = useCallback(
    async (assets: string[]) => updateAssetPrivacy(assets, false),
    [updateAssetPrivacy],
  )

  return {
    hideAssets,
    unhideAssets,
  }
}
