import React from "react"
import { useIsLessThanLg, Text, Flex } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { Overflow } from "@/components/common/Overflow"
import { useOwnerListingPriceOnAccountPage } from "@/hooks/useFlag"
import { useTranslate } from "@/hooks/useTranslate"
import { ItemMetadata$key } from "@/lib/graphql/__generated__/ItemMetadata.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"
import { getCompactPriceInfo } from "./price/utils"

type ItemMetadataProps = {
  item: ItemMetadata$key | null
}

export const useItemMetadata = ({
  item: itemKey,
}: Pick<ItemMetadataProps, "item">) => {
  /* eslint-disable relay/unused-fields */
  const item = useFragment(
    graphql`
      fragment ItemMetadata on ItemType
      @argumentDefinitions(
        identity: { type: "IdentityInputType" }
        showBestAskForOwner: { type: "Boolean", defaultValue: false }
        shouldShowBestBid: { type: "Boolean", defaultValue: false }
      ) {
        __typename
        orderData {
          bestAskV2 {
            openedAt
            createdDate
            closedAt
          }
          bestAskForOwnerItemCard: bestAskV2(byAddress: $identity)
            @include(if: $showBestAskForOwner) {
            openedAt
            createdDate
            closedAt
          }
        }
        assetEventData {
          lastSale {
            unitPriceQuantity {
              # eslint-disable-next-line relay/must-colocate-fragment-spreads
              ...AssetQuantity_data
              quantity
              asset {
                symbol
                decimals
              }
            }
          }
        }
        ... on AssetType {
          bestAllTypeBid @include(if: $shouldShowBestBid) {
            perUnitPriceType {
              unit
              symbol
            }
          }
          # Mint price is only shown in the same cases where we would also show best bid
          mintEvent @include(if: $shouldShowBestBid) {
            perUnitPrice {
              unit
              symbol
            }
          }
        }
      }
    `,
    itemKey,
  )

  const orderData = item?.orderData
  const ownerListingPriceEnabled = useOwnerListingPriceOnAccountPage()
  const bestAsk =
    (ownerListingPriceEnabled
      ? orderData?.bestAskForOwnerItemCard
      : orderData?.bestAskV2) ?? orderData?.bestAskV2
  const bestBid = item?.bestAllTypeBid
  const mintEvent = item?.mintEvent
  const lastSale = item?.assetEventData.lastSale

  return { bestAsk, bestBid, lastSale, mintEvent }
}

const getPriceDisplay = (price: BigNumber, symbol: string) => {
  return getCompactPriceInfo({
    price,
    symbol,
    maxDecimalPlaces: 2,
  })
}

export const ItemMetadata = React.memo(function ItemMetadata({
  item,
}: ItemMetadataProps): JSX.Element | null {
  const t = useTranslate("components")
  const isMdScreen = useIsLessThanLg()
  const { lastSale, bestBid, mintEvent } = useItemMetadata({ item })

  const renderText = (text: JSX.Element) => (
    <Flex className="w-full flex-nowrap items-center justify-end">
      <Text.Body
        className="w-full text-secondary"
        size={isMdScreen ? "tiny" : "small"}
      >
        <Overflow>{text}</Overflow>
      </Text.Body>
    </Flex>
  )

  if (bestBid?.perUnitPriceType) {
    const { unit, symbol } = bestBid.perUnitPriceType
    const priceDisplay = getPriceDisplay(bn(unit), symbol)
    return renderText(
      t(
        "assets.metadata.bestOffer",
        "Best offer: {{priceDisplay}} {{symbol}}",
        { priceDisplay, symbol },
      ),
    )
  }

  if (lastSale?.unitPriceQuantity) {
    const price = bn(
      lastSale.unitPriceQuantity.quantity,
      lastSale.unitPriceQuantity.asset.decimals,
    )
    const symbol = lastSale.unitPriceQuantity.asset.symbol ?? ""
    const priceDisplay = getPriceDisplay(price, symbol)

    return renderText(
      t("assets.metadata.lastSale", "Last sale: {{priceDisplay}} {{symbol}}", {
        priceDisplay,
        symbol,
      }),
    )
  }

  if (mintEvent?.perUnitPrice) {
    const { unit, symbol } = mintEvent.perUnitPrice
    const priceDisplay = getPriceDisplay(bn(unit), symbol)

    return renderText(
      t(
        "assets.metadata.mintPrice",
        "Mint price: {{priceDisplay}} {{symbol}}",
        {
          priceDisplay,
          symbol,
        },
      ),
    )
  }

  return null
})
