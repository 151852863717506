import { NextPageContext } from "next"
import { graphql } from "relay-runtime"
import { initWallet } from "@/containers/WalletProvider/wallet"
import Wallet from "@/lib/chain/wallet"
import type { identityUsernameQuery } from "@/lib/graphql/__generated__/identityUsernameQuery.graphql"
import { fetch } from "@/lib/graphql/graphql"
import { isValidAddress, resolveIdentity } from "@/lib/helpers/address"
import Router from "@/lib/helpers/router"
import { isAscii } from "@/lib/helpers/stringUtils"

export const resolveProfileIdentity = async (
  context: NextPageContext,
  identifier: string | undefined,
) => {
  const wallet = initWallet(Wallet.stateFromContext(context))

  if (!identifier) {
    const identity = wallet.getActiveAccountKey() ?? {}
    return { isCurrentUser: true, identity }
  }

  const identity = resolveIdentity(identifier)
  const isCurrentUser = wallet.isCurrentIdentity(identity)

  if (identity.address) {
    const [data] = await fetch<identityUsernameQuery>(
      graphql`
        query identityUsernameQuery($address: AddressScalar!) {
          getAccount(address: $address) {
            user {
              publicUsername
            }
          }
        }
      `,
      { address: identity.address },
    )
    const publicUsername = data.getAccount.user?.publicUsername

    // redirect from /:address -> /:username if account has a username set
    if (
      publicUsername &&
      !isValidAddress(publicUsername) &&
      isAscii(publicUsername)
    ) {
      await Router.replace(
        `/${publicUsername}`,
        Router.getQueryParams(),
        context.res,
      )
    }
  }

  return { isCurrentUser, identity }
}
