/**
 * @generated SignedSource<<df5d279c01d61eac4b6097c44f57277f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CollectionVerificationRequestStatus = "APPROVED" | "DENIED" | "PENDING" | "%future added value";
export type Config = "AFFILIATE" | "AFFILIATE_BLACKLISTED" | "AFFILIATE_REQUESTED" | "EMPLOYEE" | "MODERATOR" | "PARTNER" | "STAFF" | "VERIFIED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CollectionVerificationForm_data$data = {
  readonly bannerImageUrl: string | null;
  readonly canViewVerificationForm: boolean;
  readonly connectedTwitterUsername: string | null;
  readonly imageUrl: string | null;
  readonly name: string;
  readonly owner: {
    readonly config: Config | null;
  } | null;
  readonly relayId: string;
  readonly verificationRequest: {
    readonly resubmissionAllowed: boolean;
    readonly status: CollectionVerificationRequestStatus;
  } | null;
  readonly " $fragmentType": "CollectionVerificationForm_data";
};
export type CollectionVerificationForm_data$key = {
  readonly " $data"?: CollectionVerificationForm_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionVerificationForm_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionVerificationForm_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bannerImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "connectedTwitterUsername",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "config",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canViewVerificationForm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionVerificationRequestType",
      "kind": "LinkedField",
      "name": "verificationRequest",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "resubmissionAllowed",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "1a3bca941768f22e145a9269124abca6";

export default node;
