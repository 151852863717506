import React from "react"
import { classNames, Skeleton } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled, { css } from "styled-components"
import { Block } from "@/design-system/Block"
import { ItemCardContent$key } from "@/lib/graphql/__generated__/ItemCardContent.graphql"
import { getNodes, graphql } from "@/lib/graphql/graphql"
import { borderRadius } from "@/styles/themes"
import { AssetMedia } from "./AssetMedia"
import { ItemCardCarousel } from "./ItemCardCarousel/ItemCardCarousel.react"

type Props = {
  item: ItemCardContent$key | null
  width: number
  index?: number
  disabled?: boolean
  natural?: boolean
  showAssetMediaEditions?: boolean
  /**
   * Optional prop to let card fill the available container width instead of using given width.
   * Width prop will still be consumed for the asset media component.
   */
  fillContainerWidth?: boolean
  className?: string
  mediaClassName?: string
  sizes?: string
  // List view that should
  // 1. remove any interactions
  // 2. special bundle treatment
  isListView?: boolean
}

export const ItemCardContent = React.memo(function ItemCardContent({
  item: itemKey,
  index,
  width,
  disabled = false,
  natural = false,
  fillContainerWidth,
  sizes,
  className,
  mediaClassName,
  showAssetMediaEditions,
  isListView = false,
}: Props) {
  const item = useFragment(
    graphql`
      fragment ItemCardContent on ItemType
      @argumentDefinitions(
        identity: { type: "IdentityInputType", defaultValue: {} }
        showQuantity: { type: "Boolean", defaultValue: false }
      ) {
        __typename
        relayId
        ... on AssetType {
          relayId
          name
          ...AssetMedia_asset
            @arguments(identity: $identity, showQuantity: $showQuantity)
        }
        ... on AssetBundleType {
          assetQuantities(first: 18) {
            edges {
              node {
                asset {
                  relayId
                  ...AssetMedia_asset
                }
              }
            }
          }
        }
      }
    `,
    itemKey,
  )

  const renderContent = () => {
    if (!item) {
      return (
        <Skeleton
          className={fillContainerWidth ? "absolute inset-0" : undefined}
        >
          <Skeleton.Block />
        </Skeleton>
      )
    }

    if (item.__typename === "AssetBundleType") {
      const assets = getNodes(item.assetQuantities).map(aq => aq.asset)

      if (isListView) {
        const asset = assets[0]

        return (
          <Block className="relative">
            <ListViewBundleBackground />
            <ListViewAssetContainer>
              <AssetMedia
                asset={asset}
                key={asset.relayId}
                mediaStyles={{
                  borderTopLeftRadius: borderRadius.default,
                  borderTopRightRadius: borderRadius.default,
                }}
                rawImage={natural}
                showAssetMediaEditions={showAssetMediaEditions}
                size={width}
                sizes={sizes ?? (width ? `${width}px` : undefined)}
                useCustomPlayButton
                width={width}
              />
            </ListViewAssetContainer>
          </Block>
        )
      }

      const slides = assets.map((asset, index) => {
        const renderCollection = () => {
          const media = (
            <AssetMedia
              asset={asset}
              key={asset.relayId}
              mediaStyles={{
                borderTopLeftRadius: borderRadius.default,
                borderTopRightRadius: borderRadius.default,
              }}
              rawImage={natural}
              showAssetMediaEditions={showAssetMediaEditions}
              size={width}
              sizes={sizes ?? (width ? `${width}px` : undefined)}
              useCustomPlayButton
              width={width}
            />
          )

          if (fillContainerWidth) {
            return (
              <Block
                className="relative w-full"
                height="0"
                key={asset.relayId}
                paddingBottom="100%"
              >
                <Block className="absolute inset-0">{media}</Block>
              </Block>
            )
          }

          return media
        }

        return {
          id: `item-card-carousel-slide-${asset.relayId}-${index}-${item.relayId}`,
          collection: renderCollection(),
        }
      })

      return (
        <ItemCardCarousel
          index={index}
          slideItems={slides}
          totalSlides={slides.length}
        />
      )
    } else if (item.__typename === "AssetType") {
      const media = (
        <AssetMedia
          alt={item.name ?? ""}
          asset={item}
          className={mediaClassName}
          mediaStyles={{ borderRadius: 0 }}
          rawImage={natural}
          showAssetMediaEditions={showAssetMediaEditions}
          showControls={!isListView}
          sizes={sizes ?? (width ? `${width}px` : undefined)}
          useCustomPlayButton={!isListView}
          width={width}
        />
      )

      if (fillContainerWidth) {
        return <Block className="absolute inset-0">{media}</Block>
      }
      return media
    }
    return null
  }

  return (
    <StyledContainer
      $disabled={disabled}
      $noOverflowHidden={!!isListView}
      $useSquareAspectRatio={
        fillContainerWidth && item?.__typename !== "AssetBundleType"
      }
      className={classNames("relative", className)}
      style={
        fillContainerWidth
          ? undefined
          : { height: natural ? undefined : width, width }
      }
    >
      {renderContent()}
    </StyledContainer>
  )
})

const ListViewAssetContainer = styled(Block)`
  outline: 3px solid ${props => props.theme.colors.base1};
  position: relative;
  z-index: 1;
  border-radius: ${props => props.theme.borderRadius.default};
`

const ListViewBundleBackground = styled(Block)`
  position: absolute;
  background: ${props => props.theme.colors.components.border.level2};
  height: 80%;
  left: -10px;
  right: -10px;
  width: calc(100% + 20px);
  top: 50%;
  transform: translatey(-50%);
  border-radius: ${props => props.theme.borderRadius.squircle};
`

const StyledContainer = styled(Block)<{
  $disabled?: boolean
  $useSquareAspectRatio?: boolean
  $noOverflowHidden?: boolean
}>`
  overflow: ${props => (props.$noOverflowHidden ? "" : "hidden")};
  border-top-left-radius: ${props => props.theme.borderRadius.default};
  border-top-right-radius: ${props => props.theme.borderRadius.default};

  ${props =>
    props.$disabled &&
    css`
      opacity: 0.3;
    `}

  ${props =>
    props.$useSquareAspectRatio &&
    css`
      padding-bottom: 100%;
    `}
`
