/**
 * @generated SignedSource<<afb9a511f0f1510908a377d42223a9b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BERA_CHAIN" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "RONIN" | "SAIGON_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SHAPE" | "SOLANA" | "SOLDEV" | "SONEIUM" | "SONEIUM_MINATO" | "UNICHAIN" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useAssetSelectionStorage_item$data = {
  readonly __typename: string;
  readonly bestAllTypeBid?: {
    readonly relayId: string;
  } | null;
  readonly chain: {
    readonly identifier: ChainIdentifier;
    readonly isTradingEnabled: boolean;
  };
  readonly isCompromised?: boolean;
  readonly isErc721cFeeEnforced?: boolean;
  readonly orderData?: {
    readonly bestAskForOwner: {
      readonly maker: {
        readonly address: string;
      };
      readonly relayId: string;
    } | null;
    readonly bestAskV2: {
      readonly maker: {
        readonly address: string;
      };
      readonly relayId: string;
    } | null;
    readonly bestBidV2?: {
      readonly relayId: string;
    } | null;
  };
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"AssetContextMenu_data" | "CancelItemOrdersButton_items" | "asset_url" | "item_sellUrl">;
  readonly " $fragmentType": "useAssetSelectionStorage_item";
};
export type useAssetSelectionStorage_item$key = {
  readonly " $data"?: useAssetSelectionStorage_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"useAssetSelectionStorage_item">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useAssetSelectionStorage_item"
};

(node as any).hash = "2c8544ed4a7840ea47120faabc274dfe";

export default node;
