import { useCallback, useState } from "react"

type Item = {
  relayId: string
}

type State<T> = {
  selectedItems: readonly T[]
}

/**
 * Manages selection state for a list of items.
 *
 * Similar to useSelectionStorage, but does not persist state in local storage.
 */
export const useSelectionState = <T extends Item>(initialState: State<T>) => {
  const [state, setState] = useState<State<T>>(initialState)

  const select = useCallback(
    (item: T) => {
      setState(prev => {
        return {
          ...prev,
          selectedItems: [...prev.selectedItems, item],
        }
      })
    },
    [setState],
  )

  const batchSelect = useCallback(
    (items: T[]) => {
      setState(prev => {
        return {
          ...prev,
          selectedItems: items,
        }
      })
    },
    [setState],
  )

  const unselect = useCallback(
    (itemRelayIds: string[]) => {
      setState(prev => {
        return {
          ...prev,
          selectedItems: prev.selectedItems.filter(
            ({ relayId }) => !itemRelayIds.includes(relayId),
          ),
        }
      })
    },
    [setState],
  )

  const unselectAll = useCallback(() => {
    setState(prev => ({
      ...prev,
      selectedItems: [],
    }))
  }, [setState])

  const clear = useCallback(() => {
    setState(initialState)
  }, [initialState, setState])

  return {
    selectedItems: state.selectedItems,
    select,
    batchSelect,
    unselect,
    unselectAll,
    clear,
  }
}
